import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const OrderPriceSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  testID,
  accessibilityLabel,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    testID={testID}
    fill={color}
    viewBox="0 0 48 49"
    accessibilityLabel={accessibilityLabel}>
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.70034 2.79173C3.31136 2.40276 2.68071 2.40276 2.29173 2.79173C1.90276 3.18071 1.90276 3.81136 2.29173 4.20034L10.6286 12.5372L10.6379 12.5464L15.3603 17.2688C15.0099 17.862 14.8089 18.554 14.8089 19.2932C14.8089 21.4944 16.5918 23.2773 18.793 23.2773C20.9843 23.2773 22.7772 21.4944 22.7772 19.2932C22.7772 17.0919 20.9943 15.309 18.793 15.309C18.054 15.309 17.3621 15.51 16.769 15.8603L13.1087 12.1999L26.0585 10.5802L26.0644 10.5794C26.36 10.5406 26.6609 10.6408 26.884 10.8638L26.8842 10.8641L43.717 27.6869C44.1049 28.0748 44.1049 28.7091 43.717 29.0971L42.5715 30.2425C42.1826 30.6315 42.1826 31.2621 42.5715 31.6511C42.9605 32.0401 43.5912 32.0401 43.9802 31.6511L45.1256 30.5057C46.2915 29.3398 46.2915 27.4442 45.1256 26.2783L45.1254 26.2781L28.2926 9.45524C27.6399 8.80256 26.727 8.48437 25.8086 8.6038L11.3242 10.4156L3.70034 2.79173ZM16.8741 18.7593C17.2621 19.0269 17.7978 18.9881 18.143 18.6429C18.4882 18.2977 18.527 17.7621 18.2596 17.3741C18.4295 17.3265 18.6084 17.3011 18.793 17.3011C19.8887 17.3011 20.7851 18.1975 20.7851 19.2932C20.7851 20.3888 19.8887 21.2852 18.793 21.2852C17.6974 21.2852 16.801 20.3888 16.801 19.2932C16.801 19.1084 16.8265 18.9294 16.8741 18.7593ZM39.9263 35.7149C40.3152 35.326 40.3152 34.6953 39.9263 34.3063C39.5373 33.9174 38.9066 33.9174 38.5177 34.3063L28.6071 44.2169C28.2192 44.6048 27.5849 44.6048 27.1969 44.2169L21.7187 38.7387L21.7109 38.731L10.3638 27.3839L10.3638 27.3838L10.3557 27.3758C10.1454 27.1704 10.0397 26.8663 10.0793 26.5643L10.0801 26.5584L11.2056 17.5642C11.2739 17.0184 10.8868 16.5205 10.341 16.4522C9.79511 16.3839 9.29725 16.771 9.22895 17.3169L8.10375 26.3084C7.98543 27.2184 8.2964 28.1459 8.95882 28.7961L20.31 40.1473L20.318 40.1552L25.7883 45.6255C26.9542 46.7914 28.8498 46.7914 30.0157 45.6255L39.9263 35.7149Z"
    />
  </AccessibleSvg>
)

export const OrderPrice = styled(OrderPriceSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
