import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const LocationPointerWithBorderSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color, // pointer color
  color2, // border color
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    testID={testID}
    accessibilityLabel={accessibilityLabel}>
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9047 19.0147C11.0245 19.2833 11.1504 19.5657 11.2786 19.8795C11.95 21.5367 18.7643 34.2875 18.7643 34.2875C19.3143 35.2375 20.6857 35.2375 21.2357 34.2875C21.2357 34.2875 28.1643 21.1867 28.7214 19.8795C28.823 19.6412 28.93 19.4153 29.0371 19.1892C29.5176 18.1747 30 17.1562 30 15.0006C30 9.3879 25.5214 5 20 5C14.4786 5 10 9.35404 10 15.0006C10 16.985 10.3974 17.8765 10.9047 19.0147ZM20 19.2866C22.367 19.2866 24.2857 17.3677 24.2857 15.0006C24.2857 12.6335 22.367 10.7146 20 10.7146C17.6331 10.7146 15.7143 12.6335 15.7143 15.0006C15.7143 17.3677 17.6331 19.2866 20 19.2866Z"
    />
    <Path
      fill={color2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7337 32.3532C15.8022 28.721 11.786 21.132 11.2786 19.8795C11.1504 19.5657 11.0245 19.2833 10.9047 19.0147C10.3974 17.8765 10 16.985 10 15.0006C10 9.35404 14.4786 5 20 5C25.5214 5 30 9.3879 30 15.0006C30 17.1562 29.5176 18.1747 29.0371 19.1892C28.93 19.4153 28.823 19.6412 28.7214 19.8795C28.2989 20.8709 24.2116 28.6457 22.2614 32.3446C22.2431 32.3792 22.225 32.4135 22.2071 32.4475C21.6167 33.5672 21.2357 34.2875 21.2357 34.2875C20.6857 35.2375 19.3143 35.2375 18.7643 34.2875C18.7643 34.2875 18.3841 33.576 17.7944 32.4674C17.7744 32.4298 17.7542 32.3917 17.7337 32.3532ZM18.7643 34.2875L15.2365 36.1728C15.2578 36.2127 15.2798 36.2523 15.3025 36.2915C17.3932 39.9028 22.6068 39.9028 24.6975 36.2915C24.7231 36.2473 24.7478 36.2026 24.7717 36.1575L21.2357 34.2875C24.7717 36.1575 24.7717 36.1575 24.7717 36.1575L25.0715 35.59C25.2625 35.2285 25.5358 34.7107 25.8654 34.0854C26.5246 32.8351 27.4097 31.1534 28.3127 29.4302C30.0474 26.1196 32.0403 22.2944 32.4012 21.4478C32.4669 21.2936 32.5402 21.1376 32.6521 20.9014C33.2573 19.6236 34 17.9894 34 15.0006C34 7.15526 27.707 1 20 1C12.3019 1 6 7.11255 6 15.0006C6 16.2746 6.12917 17.346 6.39599 18.3512C6.64213 19.2785 6.98134 20.0385 7.22107 20.5756C7.23132 20.5985 7.24138 20.6211 7.25125 20.6432C7.37271 20.9157 7.47387 21.1433 7.57473 21.3899C8.01535 22.4717 10.0259 26.3247 11.7339 29.5685C12.6309 31.2722 13.5064 32.9229 14.1572 34.1471C14.4828 34.7593 14.7523 35.2653 14.9406 35.6184L15.2365 36.1728C15.2365 36.1728 15.2365 36.1728 18.7643 34.2875ZM24.2857 15.0006C24.2857 17.3677 22.367 19.2866 20 19.2866C17.6331 19.2866 15.7143 17.3677 15.7143 15.0006C15.7143 12.6335 17.6331 10.7146 20 10.7146C22.367 10.7146 24.2857 12.6335 24.2857 15.0006Z"
    />
  </AccessibleSvg>
)

export const LocationPointerWithBorder = styled(LocationPointerWithBorderSvg).attrs(
  ({ color, color2, size, theme }) => ({
    color: color ?? theme.colors.black,
    color2: color2 ?? undefined,
    size: size ?? theme.icons.sizes.smaller,
  })
)``
