import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const WrongSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    accessibilityLabel={accessibilityLabel}
    testID={testID}>
    <Path
      fill={color}
      d="M24 43.0909C13.4545 43.0909 4.90909 34.5455 4.90909 24C4.90909 20.7523 5.71845 17.6905 7.15309 15.021C7.40265 14.5566 7.22851 13.9778 6.76414 13.7283C6.29977 13.4787 5.72101 13.6529 5.47145 14.1172C3.88973 17.0604 3 20.4322 3 24C3 35.5999 12.4001 45 24 45C35.5999 45 45 35.5999 45 24C45 12.4001 35.5999 3 24 3C20.3809 3 16.9697 3.92015 13.9891 5.54046C13.5259 5.79225 13.3546 6.37183 13.6064 6.835C13.8582 7.29816 14.4377 7.46952 14.9009 7.21773C17.6094 5.7453 20.7082 4.90909 24 4.90909C34.5455 4.90909 43.0909 13.4545 43.0909 24C43.0909 34.5455 34.5455 43.0909 24 43.0909ZM15.2936 15.2922C15.6845 14.9021 16.3176 14.9027 16.7078 15.2936L23.9847 22.5846L31.2538 15.3322C31.6448 14.9421 32.278 14.9428 32.668 15.3338C33.0581 15.7248 33.0574 16.3579 32.6664 16.748L25.4023 23.9955L32.7049 31.2507C33.0966 31.6399 33.0987 32.2731 32.7095 32.6649C32.3202 33.0567 31.687 33.0588 31.2952 32.6695L23.9939 25.4155L16.7539 32.7047C16.3647 33.0965 15.7315 33.0987 15.3397 32.7095C14.9478 32.3203 14.9457 31.6871 15.3349 31.2953L22.5763 24.0046L15.2922 16.7064C14.9021 16.3155 14.9027 15.6823 15.2936 15.2922Z"
    />
  </AccessibleSvg>
)

export const Wrong = styled(WrongSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
