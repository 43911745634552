import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const MapPinSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    accessibilityLabel={accessibilityLabel}
    testID={testID}>
    <Path
      d="M16 0.773268C9.38 0.773268 4 6.15993 4 12.7733C4 17.2133 6.55333 20.1933 9.02 23.0733L9.15333 23.2266L11.8867 26.4199L14.62 29.6133L14.98 30.0333L15.9933 31.2133L17.0067 30.0333C17.8467 29.0533 18.6867 28.0666 19.5267 27.0799C21.1733 25.1466 22.88 23.1466 24.5733 21.1933C26.84 18.5733 27.9933 15.7399 27.9933 12.7666C27.9933 6.1466 22.6133 0.766602 15.9933 0.766602L16 0.773268ZM16 11.4399C16.7333 11.4399 17.3333 12.0399 17.3333 12.7733C17.3333 13.5066 16.7333 14.1066 16 14.1066C15.2667 14.1066 14.6667 13.5066 14.6667 12.7733C14.6667 12.0399 15.2667 11.4399 16 11.4399Z"
      fill="white"
    />
    <Path
      d="M10.0999 22.1534C7.69325 19.3468 5.41992 16.6934 5.41992 12.7734C5.41992 6.9401 10.1666 2.18677 16.0066 2.18677C21.8466 2.18677 26.5933 6.93343 26.5933 12.7734C26.5933 15.4001 25.5599 17.9201 23.5199 20.2734C21.8199 22.2334 20.1333 24.2068 18.4533 26.1868C17.6399 27.1401 16.8266 28.0934 16.0133 29.0468L10.1133 22.1534H10.0999ZM15.9999 10.0268C14.4866 10.0268 13.2533 11.2601 13.2533 12.7734C13.2533 14.2868 14.4866 15.5201 15.9999 15.5201C17.5133 15.5201 18.7466 14.2868 18.7466 12.7734C18.7466 11.2601 17.5133 10.0268 15.9999 10.0268Z"
      fill={color}
    />
    <Path
      d="M15.9997 2.27343C21.7863 2.27343 26.4997 6.98676 26.4997 12.7734C26.4997 15.3801 25.473 17.8801 23.4463 20.2201C21.753 22.1734 20.0463 24.1801 18.393 26.1134C17.593 27.0468 16.7997 27.9801 15.9997 28.9134L15.7663 28.6401L13.033 25.4468L10.2997 22.2534L10.1663 22.1001C7.77301 19.3068 5.50634 16.6601 5.50634 12.7734C5.50634 6.98676 10.2197 2.27343 16.0063 2.27343M15.9997 15.6068C17.5597 15.6068 18.833 14.3334 18.833 12.7734C18.833 11.2134 17.5597 9.9401 15.9997 9.9401C14.4397 9.9401 13.1663 11.2134 13.1663 12.7734C13.1663 14.3334 14.4397 15.6068 15.9997 15.6068ZM15.9997 2.10676C10.1063 2.10676 5.33301 6.8801 5.33301 12.7734C5.33301 16.7934 7.70634 19.4868 10.1663 22.3601C11.993 24.4868 13.813 26.6201 15.6397 28.7468C15.7597 28.8868 15.8797 29.0268 15.9997 29.1668C18.5263 26.2201 21.033 23.2534 23.573 20.3201C25.4397 18.1668 26.6663 15.6601 26.6663 12.7668C26.6663 6.87343 21.893 2.1001 15.9997 2.1001V2.10676ZM15.9997 15.4401C14.5263 15.4401 13.333 14.2468 13.333 12.7734C13.333 11.3001 14.5263 10.1068 15.9997 10.1068C17.473 10.1068 18.6663 11.3001 18.6663 12.7734C18.6663 14.2468 17.473 15.4401 15.9997 15.4401Z"
      fill={color}
    />
  </AccessibleSvg>
)

export const MapPin = styled(MapPinSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
