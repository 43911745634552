import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const ShowSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    testID={testID}
    accessibilityLabel={accessibilityLabel}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.03395 13.1411C3.35403 12.502 2.29712 11.4855 1.9206 11.0273C1.73217 10.7979 1.58577 10.608 1.48586 10.4745C1.4359 10.4077 1.39753 10.355 1.37132 10.3185L1.34117 10.2762L1.33306 10.2646L1.33077 10.2613L1.32982 10.26C1.22337 10.1062 1.2234 9.89392 1.32986 9.74012L1.33013 9.73973L1.33092 9.73859L1.33353 9.73485L1.34283 9.72162C1.35083 9.71029 1.36242 9.69399 1.37751 9.67305C1.40768 9.63117 1.45188 9.57072 1.50941 9.49436C1.62442 9.34165 1.79287 9.12508 2.00917 8.8659C2.4413 8.34811 3.06682 7.6574 3.841 6.96572C5.3783 5.59227 7.55575 4.16675 10 4.16675C12.4443 4.16675 14.6217 5.59227 16.159 6.96572C16.9332 7.6574 17.5587 8.34811 17.9908 8.8659C18.2071 9.12508 18.3756 9.34165 18.4906 9.49436C18.5481 9.57072 18.5923 9.63117 18.6225 9.67305C18.6376 9.69399 18.6492 9.71029 18.6572 9.72162L18.6665 9.73485L18.6691 9.73859L18.6699 9.73973L18.6701 9.74012C18.7766 9.89393 18.7766 10.1062 18.6701 10.26L18.6699 10.2604L18.6691 10.2616L18.6665 10.2653L18.6572 10.2785C18.6492 10.2899 18.6376 10.3062 18.6225 10.3271C18.5923 10.369 18.5481 10.4294 18.4906 10.5058C18.3756 10.6585 18.2071 10.8751 17.9908 11.1343C17.5587 11.6521 16.7402 12.4494 15.966 13.1411C14.4287 14.5146 12.4443 15.8334 10 15.8334C7.51702 15.8334 6.12009 14.7536 4.201 13.2702L4.03395 13.1411ZM13.75 10.0001C13.75 12.1635 12.0718 13.7501 10 13.7501C7.92821 13.7501 6.25 12.0719 6.25 10.0001C6.25 7.9283 8.125 6.25008 10 6.25008C11.875 6.25008 13.75 7.83666 13.75 10.0001ZM10 11.6668C10.8463 11.6668 11.5453 11.036 11.6525 10.219C11.4809 10.3434 11.2698 10.4167 11.0417 10.4167C10.4664 10.4167 10 9.95038 10 9.37508C10 8.96376 10.2384 8.60812 10.5846 8.4388C10.4027 8.37067 10.2057 8.33341 10 8.33341C9.07953 8.33341 8.33333 9.07961 8.33333 10.0001C8.33333 10.9206 9.07953 11.6668 10 11.6668Z"
      fill={color}
    />
  </AccessibleSvg>
)

export const Show = styled(ShowSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.smaller,
}))``
