/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Service API Document
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import { getAuthenticationHeaders, handleGeneratedApiResponse, safeFetch } from 'api/apiHelpers'
import { EmptyResponse } from 'libs/fetch'

import { Configuration } from './configuration'

const BASE_PATH = '/'.replace(/\/+$/, '')

/**
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>
}

/**
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string
  options: any
}

/**
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration?: Configuration
  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH
  ) {
    if (configuration) {
      this.configuration = configuration
      this.basePath = configuration.basePath || this.basePath
    }
  }

  public getConfiguration = () => {
    return this.configuration
  }
}

/**
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError'
  constructor(
    public field: string,
    msg?: string
  ) {
    super(msg)
  }
}

/**
 * @export
 * @interface AccountRequest
 */
export interface AccountRequest {
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  appsFlyerPlatform?: string | null
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  appsFlyerUserId?: string | null
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  birthdate: string
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  email: string
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  firebasePseudoId?: string | null
  /**
   * @type {boolean}
   * @memberof AccountRequest
   */
  marketingEmailSubscription?: boolean | null
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  password: string
  /**
   * @type {string}
   * @memberof AccountRequest
   */
  token: string
  /**
   * @type {TrustedDevice}
   * @memberof AccountRequest
   */
  trustedDevice?: TrustedDevice | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AccountState {
  'ACTIVE' = 'ACTIVE',
  'ANONYMIZED' = 'ANONYMIZED',
  'INACTIVE' = 'INACTIVE',
  'SUSPENDED' = 'SUSPENDED',
  'SUSPENDED_UPON_USER_REQUEST' = 'SUSPENDED_UPON_USER_REQUEST',
  'SUSPICIOUS_LOGIN_REPORTED_BY_USER' = 'SUSPICIOUS_LOGIN_REPORTED_BY_USER',
  'DELETED' = 'DELETED',
  'WAITING_FOR_ANONYMIZATION' = 'WAITING_FOR_ANONYMIZATION',
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum AchievementEnum {
  'FIRST_MOVIE_BOOKING' = 'FIRST_MOVIE_BOOKING',
  'FIRST_BOOK_BOOKING' = 'FIRST_BOOK_BOOKING',
  'FIRST_RECORDED_MUSIC_BOOKING' = 'FIRST_RECORDED_MUSIC_BOOKING',
  'FIRST_SHOW_BOOKING' = 'FIRST_SHOW_BOOKING',
  'FIRST_MUSEUM_BOOKING' = 'FIRST_MUSEUM_BOOKING',
  'FIRST_LIVE_MUSIC_BOOKING' = 'FIRST_LIVE_MUSIC_BOOKING',
  'FIRST_NEWS_BOOKING' = 'FIRST_NEWS_BOOKING',
  'FIRST_INSTRUMENT_BOOKING' = 'FIRST_INSTRUMENT_BOOKING',
  'FIRST_ART_LESSON_BOOKING' = 'FIRST_ART_LESSON_BOOKING',
}
/**
 * @export
 * @interface AchievementResponse
 */
export interface AchievementResponse {
  /**
   * @type {number}
   * @memberof AchievementResponse
   */
  id: number
  /**
   * @type {AchievementEnum}
   * @memberof AchievementResponse
   */
  name: AchievementEnum
  /**
   * @type {string}
   * @memberof AchievementResponse
   */
  seenDate?: string | null
  /**
   * @type {string}
   * @memberof AchievementResponse
   */
  unlockedDate: string
}
/**
 * @export
 * @interface AchievementsResponse
 */
export interface AchievementsResponse extends Array<AchievementResponse> {}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ActivityIdEnum {
  'MIDDLE_SCHOOL_STUDENT' = 'MIDDLE_SCHOOL_STUDENT',
  'HIGH_SCHOOL_STUDENT' = 'HIGH_SCHOOL_STUDENT',
  'STUDENT' = 'STUDENT',
  'EMPLOYEE' = 'EMPLOYEE',
  'APPRENTICE' = 'APPRENTICE',
  'APPRENTICE_STUDENT' = 'APPRENTICE_STUDENT',
  'VOLUNTEER' = 'VOLUNTEER',
  'INACTIVE' = 'INACTIVE',
  'UNEMPLOYED' = 'UNEMPLOYED',
}
/**
 * @export
 * @interface ActivityResponseModel
 */
export interface ActivityResponseModel {
  /**
   * @type {string}
   * @memberof ActivityResponseModel
   */
  description?: string | null
  /**
   * @type {ActivityIdEnum}
   * @memberof ActivityResponseModel
   */
  id: ActivityIdEnum
  /**
   * @type {string}
   * @memberof ActivityResponseModel
   */
  label: string
}
/**
 * @export
 * @interface ActivityTypesResponse
 */
export interface ActivityTypesResponse {
  /**
   * @type {Array<ActivityResponseModel>}
   * @memberof ActivityTypesResponse
   */
  activities: Array<ActivityResponseModel>
}
/**
 * @export
 * @interface ArtistResponse
 */
export interface ArtistResponse {
  /**
   * @type {string}
   * @memberof ArtistResponse
   */
  id: string
  /**
   * @type {string}
   * @memberof ArtistResponse
   */
  name: string
}
/**
 * @export
 * @interface AudioDisabilityModel
 */
export interface AudioDisabilityModel {
  /**
   * @type {Array<string>}
   * @memberof AudioDisabilityModel
   */
  deafAndHardOfHearing?: Array<string>
}
/**
 * @export
 * @interface AvailableReactionBooking
 */
export interface AvailableReactionBooking {
  /**
   * @type {string}
   * @memberof AvailableReactionBooking
   */
  dateUsed?: string | null
  /**
   * @type {string}
   * @memberof AvailableReactionBooking
   */
  image?: string | null
  /**
   * @type {string}
   * @memberof AvailableReactionBooking
   */
  name: string
  /**
   * @type {number}
   * @memberof AvailableReactionBooking
   */
  offerId: number
  /**
   * @type {string}
   * @memberof AvailableReactionBooking
   */
  subcategoryId: string
}
/**
 * @export
 * @interface Banner
 */
export interface Banner {
  /**
   * @type {BannerName}
   * @memberof Banner
   */
  name: BannerName
  /**
   * @type {string}
   * @memberof Banner
   */
  text: string
  /**
   * @type {string}
   * @memberof Banner
   */
  title: string
}
/**
 * @export
 * @interface BannerMetaModel
 */
export interface BannerMetaModel {
  /**
   * @type {string}
   * @memberof BannerMetaModel
   */
  image_credit?: string | null
  /**
   * @type {string}
   * @memberof BannerMetaModel
   */
  image_credit_url?: string | null
  /**
   * @type {boolean}
   * @memberof BannerMetaModel
   */
  is_from_google?: boolean | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum BannerName {
  'geolocation_banner' = 'geolocation_banner',
  'activation_banner' = 'activation_banner',
  'retry_identity_check_banner' = 'retry_identity_check_banner',
  'transition_17_18_banner' = 'transition_17_18_banner',
}
/**
 * @export
 * @interface BannerQueryParams
 */
export interface BannerQueryParams {
  /**
   * @type {boolean}
   * @memberof BannerQueryParams
   */
  isGeolocated?: boolean
}
/**
 * @export
 * @interface BannerResponse
 */
export interface BannerResponse {
  /**
   * @type {Banner}
   * @memberof BannerResponse
   */
  banner?: Banner | null
}
/**
 * @export
 * @interface BookOfferRequest
 */
export interface BookOfferRequest {
  /**
   * @type {number}
   * @memberof BookOfferRequest
   */
  quantity: number
  /**
   * @type {number}
   * @memberof BookOfferRequest
   */
  stockId: number
}
/**
 * @export
 * @interface BookOfferResponse
 */
export interface BookOfferResponse {
  /**
   * @type {number}
   * @memberof BookOfferResponse
   */
  bookingId: number
}
/**
 * @export
 * @interface BookSubType
 */
export interface BookSubType {
  /**
   * @type {Array<GTL>}
   * @memberof BookSubType
   */
  gtls: Array<GTL>
  /**
   * @type {string}
   * @memberof BookSubType
   */
  label: string
  /**
   * @type {number}
   * @memberof BookSubType
   */
  position: number
}
/**
 * @export
 * @interface BookType
 */
export interface BookType {
  /**
   * @type {Array<BookSubType>}
   * @memberof BookType
   */
  children: Array<BookSubType>
  /**
   * @type {Array<GTL>}
   * @memberof BookType
   */
  gtls: Array<GTL>
  /**
   * @type {string}
   * @memberof BookType
   */
  label: string
  /**
   * @type {number}
   * @memberof BookType
   */
  position: number
}
/**
 * @export
 * @interface BookingActivationCodeResponse
 */
export interface BookingActivationCodeResponse {
  /**
   * @type {string}
   * @memberof BookingActivationCodeResponse
   */
  code: string
  /**
   * @type {string}
   * @memberof BookingActivationCodeResponse
   */
  expirationDate?: string | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum BookingCancellationReasons {
  'OFFERER' = 'OFFERER',
  'BENEFICIARY' = 'BENEFICIARY',
  'EXPIRED' = 'EXPIRED',
  'FRAUD' = 'FRAUD',
  'FRAUD_SUSPICION' = 'FRAUD_SUSPICION',
  'FRAUD_INAPPROPRIATE' = 'FRAUD_INAPPROPRIATE',
  'REFUSED_BY_INSTITUTE' = 'REFUSED_BY_INSTITUTE',
  'FINANCE_INCIDENT' = 'FINANCE_INCIDENT',
  'BACKOFFICE' = 'BACKOFFICE',
  'BACKOFFICE_EVENT_CANCELLED' = 'BACKOFFICE_EVENT_CANCELLED',
  'BACKOFFICE_OVERBOOKING' = 'BACKOFFICE_OVERBOOKING',
  'BACKOFFICE_BENEFICIARY_REQUEST' = 'BACKOFFICE_BENEFICIARY_REQUEST',
  'BACKOFFICE_OFFER_MODIFIED' = 'BACKOFFICE_OFFER_MODIFIED',
  'BACKOFFICE_OFFER_WITH_WRONG_INFORMATION' = 'BACKOFFICE_OFFER_WITH_WRONG_INFORMATION',
  'BACKOFFICE_OFFERER_BUSINESS_CLOSED' = 'BACKOFFICE_OFFERER_BUSINESS_CLOSED',
  'OFFERER_CONNECT_AS' = 'OFFERER_CONNECT_AS',
}
/**
 * @export
 * @interface BookingDisplayStatusRequest
 */
export interface BookingDisplayStatusRequest {
  /**
   * @type {boolean}
   * @memberof BookingDisplayStatusRequest
   */
  ended: boolean
}
/**
 * @export
 * @interface BookingOfferExtraData
 */
export interface BookingOfferExtraData {
  /**
   * @type {string}
   * @memberof BookingOfferExtraData
   */
  ean?: string | null
}
/**
 * @export
 * @interface BookingOfferResponse
 */
export interface BookingOfferResponse {
  /**
   * @type {BookingOfferResponseAddress}
   * @memberof BookingOfferResponse
   */
  address?: BookingOfferResponseAddress | null
  /**
   * @type {string}
   * @memberof BookingOfferResponse
   */
  bookingContact?: string | null
  /**
   * @type {BookingOfferExtraData}
   * @memberof BookingOfferResponse
   */
  extraData?: BookingOfferExtraData | null
  /**
   * @type {number}
   * @memberof BookingOfferResponse
   */
  id: number
  /**
   * @type {OfferImageResponse}
   * @memberof BookingOfferResponse
   */
  image?: OfferImageResponse | null
  /**
   * @type {boolean}
   * @memberof BookingOfferResponse
   */
  isDigital: boolean
  /**
   * @type {boolean}
   * @memberof BookingOfferResponse
   */
  isPermanent: boolean
  /**
   * @type {string}
   * @memberof BookingOfferResponse
   */
  name: string
  /**
   * @type {SubcategoryIdEnum}
   * @memberof BookingOfferResponse
   */
  subcategoryId: SubcategoryIdEnum
  /**
   * @type {string}
   * @memberof BookingOfferResponse
   */
  url?: string | null
  /**
   * @type {BookingVenueResponse}
   * @memberof BookingOfferResponse
   */
  venue: BookingVenueResponse
  /**
   * @type {number}
   * @memberof BookingOfferResponse
   */
  withdrawalDelay?: number | null
  /**
   * @type {string}
   * @memberof BookingOfferResponse
   */
  withdrawalDetails?: string | null
  /**
   * @type {WithdrawalTypeEnum}
   * @memberof BookingOfferResponse
   */
  withdrawalType?: WithdrawalTypeEnum | null
}
/**
 * @export
 * @interface BookingOfferResponseAddress
 */
export interface BookingOfferResponseAddress {
  /**
   * @type {string}
   * @memberof BookingOfferResponseAddress
   */
  city: string
  /**
   * @type {Coordinates}
   * @memberof BookingOfferResponseAddress
   */
  coordinates: Coordinates
  /**
   * @type {string}
   * @memberof BookingOfferResponseAddress
   */
  label?: string | null
  /**
   * @type {string}
   * @memberof BookingOfferResponseAddress
   */
  postalCode: string
  /**
   * @type {string}
   * @memberof BookingOfferResponseAddress
   */
  street?: string | null
  /**
   * @type {string}
   * @memberof BookingOfferResponseAddress
   */
  timezone: string
}
/**
 * @export
 * @interface BookingReponse
 */
export interface BookingReponse {
  /**
   * @type {BookingActivationCodeResponse}
   * @memberof BookingReponse
   */
  activationCode?: BookingActivationCodeResponse | null
  /**
   * @type {boolean}
   * @memberof BookingReponse
   */
  canReact: boolean
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  cancellationDate?: string | null
  /**
   * @type {BookingCancellationReasons}
   * @memberof BookingReponse
   */
  cancellationReason?: BookingCancellationReasons | null
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  completedUrl?: string | null
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  confirmationDate?: string | null
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  dateCreated: string
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  dateUsed?: string | null
  /**
   * @type {boolean}
   * @memberof BookingReponse
   */
  enablePopUpReaction: boolean
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  expirationDate?: string | null
  /**
   * @type {Array<ExternalBookingResponse>}
   * @memberof BookingReponse
   */
  externalBookings?: Array<ExternalBookingResponse> | null
  /**
   * @type {number}
   * @memberof BookingReponse
   */
  id: number
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  qrCodeData?: string | null
  /**
   * @type {number}
   * @memberof BookingReponse
   */
  quantity: number
  /**
   * @type {BookingStockResponse}
   * @memberof BookingReponse
   */
  stock: BookingStockResponse
  /**
   * @type {string}
   * @memberof BookingReponse
   */
  token?: string | null
  /**
   * @type {number}
   * @memberof BookingReponse
   */
  totalAmount: number
  /**
   * @type {ReactionTypeEnum}
   * @memberof BookingReponse
   */
  userReaction?: ReactionTypeEnum | null
}
/**
 * @export
 * @interface BookingStockResponse
 */
export interface BookingStockResponse {
  /**
   * @type {string}
   * @memberof BookingStockResponse
   */
  beginningDatetime?: string | null
  /**
   * @type {Array<string>}
   * @memberof BookingStockResponse
   */
  features: Array<string>
  /**
   * @type {number}
   * @memberof BookingStockResponse
   */
  id: number
  /**
   * @type {BookingOfferResponse}
   * @memberof BookingStockResponse
   */
  offer: BookingOfferResponse
  /**
   * @type {number}
   * @memberof BookingStockResponse
   */
  price: number
  /**
   * @type {string}
   * @memberof BookingStockResponse
   */
  priceCategoryLabel?: string | null
}
/**
 * @export
 * @interface BookingVenueResponse
 */
export interface BookingVenueResponse {
  /**
   * @type {string}
   * @memberof BookingVenueResponse
   */
  address?: string | null
  /**
   * @type {string}
   * @memberof BookingVenueResponse
   */
  city?: string | null
  /**
   * @type {Coordinates}
   * @memberof BookingVenueResponse
   */
  coordinates: Coordinates
  /**
   * @type {number}
   * @memberof BookingVenueResponse
   */
  id: number
  /**
   * @type {string}
   * @memberof BookingVenueResponse
   */
  name: string
  /**
   * @type {string}
   * @memberof BookingVenueResponse
   */
  postalCode?: string | null
  /**
   * @type {string}
   * @memberof BookingVenueResponse
   */
  publicName?: string | null
  /**
   * @type {string}
   * @memberof BookingVenueResponse
   */
  timezone: string
}
/**
 * @export
 * @interface BookingsResponse
 */
export interface BookingsResponse {
  /**
   * @type {Array<BookingReponse>}
   * @memberof BookingsResponse
   */
  ended_bookings: Array<BookingReponse>
  /**
   * @type {boolean}
   * @memberof BookingsResponse
   */
  hasBookingsAfter18: boolean
  /**
   * @type {Array<BookingReponse>}
   * @memberof BookingsResponse
   */
  ongoing_bookings: Array<BookingReponse>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CallToActionIcon {
  'EMAIL' = 'EMAIL',
  'RETRY' = 'RETRY',
  'EXTERNAL' = 'EXTERNAL',
}
/**
 * @export
 * @interface CallToActionMessage
 */
export interface CallToActionMessage {
  /**
   * @type {CallToActionIcon}
   * @memberof CallToActionMessage
   */
  callToActionIcon?: CallToActionIcon | null
  /**
   * @type {string}
   * @memberof CallToActionMessage
   */
  callToActionLink?: string | null
  /**
   * @type {string}
   * @memberof CallToActionMessage
   */
  callToActionTitle?: string | null
}
/**
 * @export
 * @interface CategoriesResponseModel
 */
export interface CategoriesResponseModel {
  /**
   * @type {Array<CategoryResponseModel>}
   * @memberof CategoriesResponseModel
   */
  categories: Array<CategoryResponseModel>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CategoryIdEnum {
  'BEAUX_ARTS' = 'BEAUX_ARTS',
  'CARTE_JEUNES' = 'CARTE_JEUNES',
  'CINEMA' = 'CINEMA',
  'CONFERENCE' = 'CONFERENCE',
  'FILM' = 'FILM',
  'INSTRUMENT' = 'INSTRUMENT',
  'JEU' = 'JEU',
  'LIVRE' = 'LIVRE',
  'MEDIA' = 'MEDIA',
  'MUSEE' = 'MUSEE',
  'MUSIQUE_ENREGISTREE' = 'MUSIQUE_ENREGISTREE',
  'MUSIQUE_LIVE' = 'MUSIQUE_LIVE',
  'PRATIQUE_ART' = 'PRATIQUE_ART',
  'SPECTACLE' = 'SPECTACLE',
  'TECHNIQUE' = 'TECHNIQUE',
}
/**
 * @export
 * @interface CategoryResponseModel
 */
export interface CategoryResponseModel {
  /**
   * @type {Array<string>}
   * @memberof CategoryResponseModel
   */
  gtls?: Array<string> | null
  /**
   * @type {string}
   * @memberof CategoryResponseModel
   */
  label: string
  /**
   * @type {Array<string>}
   * @memberof CategoryResponseModel
   */
  parents: Array<string>
  /**
   * @type {{ [key: string]: number; }}
   * @memberof CategoryResponseModel
   */
  positions?: { [key: string]: number } | null
  /**
   * @type {string}
   * @memberof CategoryResponseModel
   */
  searchFilter?: string | null
  /**
   * @type {string}
   * @memberof CategoryResponseModel
   */
  searchValue?: string | null
}
/**
 * @export
 * @interface ChangeBeneficiaryEmailBody
 */
export interface ChangeBeneficiaryEmailBody {
  /**
   * @type {TrustedDevice}
   * @memberof ChangeBeneficiaryEmailBody
   */
  deviceInfo?: TrustedDevice | null
  /**
   * @type {string}
   * @memberof ChangeBeneficiaryEmailBody
   */
  token: string
}
/**
 * @export
 * @interface ChangeBeneficiaryEmailResponse
 */
export interface ChangeBeneficiaryEmailResponse {
  /**
   * @type {string}
   * @memberof ChangeBeneficiaryEmailResponse
   */
  accessToken: string
  /**
   * @type {string}
   * @memberof ChangeBeneficiaryEmailResponse
   */
  refreshToken: string
}
/**
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
  /**
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  currentPassword: string
  /**
   * @type {string}
   * @memberof ChangePasswordRequest
   */
  newPassword: string
}
/**
 * @export
 * @interface ChronicleAuthor
 */
export interface ChronicleAuthor {
  /**
   * @type {number}
   * @memberof ChronicleAuthor
   */
  age?: number | null
  /**
   * @type {string}
   * @memberof ChronicleAuthor
   */
  city?: string | null
  /**
   * @type {string}
   * @memberof ChronicleAuthor
   */
  firstName?: string | null
}
/**
 * @export
 * @interface ChroniclePreview
 */
export interface ChroniclePreview {
  /**
   * @type {ChronicleAuthor}
   * @memberof ChroniclePreview
   */
  author?: ChronicleAuthor | null
  /**
   * @type {string}
   * @memberof ChroniclePreview
   */
  contentPreview: string
  /**
   * @type {string}
   * @memberof ChroniclePreview
   */
  dateCreated: string
  /**
   * @type {number}
   * @memberof ChroniclePreview
   */
  id: number
}
/**
 * @export
 * @interface Consent
 */
export interface Consent {
  /**
   * @type {Array<string>}
   * @memberof Consent
   */
  accepted: Array<string>
  /**
   * @type {Array<string>}
   * @memberof Consent
   */
  mandatory: Array<string>
  /**
   * @type {Array<string>}
   * @memberof Consent
   */
  refused: Array<string>
}
/**
 * @export
 * @interface CookieConsentRequest
 */
export interface CookieConsentRequest {
  /**
   * @type {string}
   * @memberof CookieConsentRequest
   */
  choiceDatetime: string
  /**
   * @type {Consent}
   * @memberof CookieConsentRequest
   */
  consent: Consent
  /**
   * @type {string}
   * @memberof CookieConsentRequest
   */
  deviceId: string
  /**
   * @type {number}
   * @memberof CookieConsentRequest
   */
  userId?: number | null
}
/**
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
  /**
   * @type {number}
   * @memberof Coordinates
   */
  latitude?: number | null
  /**
   * @type {number}
   * @memberof Coordinates
   */
  longitude?: number | null
}
/**
 * @export
 * @interface Credit
 */
export interface Credit {
  /**
   * @type {number}
   * @memberof Credit
   */
  initial: number
  /**
   * @type {number}
   * @memberof Credit
   */
  remaining: number
}
/**
 * @export
 * @interface CulturalSurveyAnswer
 */
export interface CulturalSurveyAnswer {
  /**
   * @type {CulturalSurveyAnswerEnum}
   * @memberof CulturalSurveyAnswer
   */
  id: CulturalSurveyAnswerEnum
  /**
   * @type {CulturalSurveyQuestionEnum}
   * @memberof CulturalSurveyAnswer
   */
  sub_question?: CulturalSurveyQuestionEnum | null
  /**
   * @type {string}
   * @memberof CulturalSurveyAnswer
   */
  subtitle?: string | null
  /**
   * @type {string}
   * @memberof CulturalSurveyAnswer
   */
  title: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CulturalSurveyAnswerEnum {
  'FESTIVAL' = 'FESTIVAL',
  'SPECTACLE' = 'SPECTACLE',
  'BIBLIOTHEQUE' = 'BIBLIOTHEQUE',
  'EVENEMENT_JEU' = 'EVENEMENT_JEU',
  'CONCERT' = 'CONCERT',
  'CINEMA' = 'CINEMA',
  'MUSEE' = 'MUSEE',
  'CONFERENCE' = 'CONFERENCE',
  'COURS' = 'COURS',
  'SANS_SORTIES' = 'SANS_SORTIES',
  'FESTIVAL_MUSIQUE' = 'FESTIVAL_MUSIQUE',
  'FESTIVAL_AVANT_PREMIERE' = 'FESTIVAL_AVANT_PREMIERE',
  'FESTIVAL_SPECTACLE' = 'FESTIVAL_SPECTACLE',
  'FESTIVAL_LIVRE' = 'FESTIVAL_LIVRE',
  'FESTIVAL_CINEMA' = 'FESTIVAL_CINEMA',
  'FESTIVAL_AUTRE' = 'FESTIVAL_AUTRE',
  'SPECTACLE_HUMOUR' = 'SPECTACLE_HUMOUR',
  'SPECTACLE_THEATRE' = 'SPECTACLE_THEATRE',
  'SPECTACLE_RUE' = 'SPECTACLE_RUE',
  'SPECTACLE_OPERA' = 'SPECTACLE_OPERA',
  'SPECTACLE_CIRQUE' = 'SPECTACLE_CIRQUE',
  'SPECTACLE_DANSE' = 'SPECTACLE_DANSE',
  'SPECTACLE_AUTRE' = 'SPECTACLE_AUTRE',
  'MATERIEL_ART_CREATIF' = 'MATERIEL_ART_CREATIF',
  'PODCAST' = 'PODCAST',
  'LIVRE' = 'LIVRE',
  'JOUE_INSTRUMENT' = 'JOUE_INSTRUMENT',
  'PRESSE_EN_LIGNE' = 'PRESSE_EN_LIGNE',
  'JEU_VIDEO' = 'JEU_VIDEO',
  'FILM_DOMICILE' = 'FILM_DOMICILE',
  'SANS_ACTIVITES' = 'SANS_ACTIVITES',
  'PROJECTION_FESTIVAL' = 'PROJECTION_FESTIVAL',
  'PROJECTION_CINEMA' = 'PROJECTION_CINEMA',
  'PROJECTION_VISITE' = 'PROJECTION_VISITE',
  'PROJECTION_CONCERT' = 'PROJECTION_CONCERT',
  'PROJECTION_CD_VINYLE' = 'PROJECTION_CD_VINYLE',
  'PROJECTION_SPECTACLE' = 'PROJECTION_SPECTACLE',
  'PROJECTION_ACTIVITE_ARTISTIQUE' = 'PROJECTION_ACTIVITE_ARTISTIQUE',
  'PROJECTION_LIVRE' = 'PROJECTION_LIVRE',
  'PROJECTION_CONFERENCE' = 'PROJECTION_CONFERENCE',
  'PROJECTION_JEU' = 'PROJECTION_JEU',
  'PROJECTION_AUTRE' = 'PROJECTION_AUTRE',
}
/**
 * @export
 * @interface CulturalSurveyAnswersRequest
 */
export interface CulturalSurveyAnswersRequest {
  /**
   * @type {Array<CulturalSurveyUserAnswer>}
   * @memberof CulturalSurveyAnswersRequest
   */
  answers: Array<CulturalSurveyUserAnswer>
}
/**
 * @export
 * @interface CulturalSurveyQuestion
 */
export interface CulturalSurveyQuestion {
  /**
   * @type {Array<CulturalSurveyAnswer>}
   * @memberof CulturalSurveyQuestion
   */
  answers: Array<CulturalSurveyAnswer>
  /**
   * @type {CulturalSurveyQuestionEnum}
   * @memberof CulturalSurveyQuestion
   */
  id: CulturalSurveyQuestionEnum
  /**
   * @type {string}
   * @memberof CulturalSurveyQuestion
   */
  title: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CulturalSurveyQuestionEnum {
  'SORTIES' = 'SORTIES',
  'FESTIVALS' = 'FESTIVALS',
  'SPECTACLES' = 'SPECTACLES',
  'ACTIVITES' = 'ACTIVITES',
  'PROJECTIONS' = 'PROJECTIONS',
}
/**
 * @export
 * @interface CulturalSurveyQuestionsResponse
 */
export interface CulturalSurveyQuestionsResponse {
  /**
   * @type {Array<CulturalSurveyQuestion>}
   * @memberof CulturalSurveyQuestionsResponse
   */
  questions: Array<CulturalSurveyQuestion>
}
/**
 * @export
 * @interface CulturalSurveyUserAnswer
 */
export interface CulturalSurveyUserAnswer {
  /**
   * @type {Array<CulturalSurveyAnswerEnum>}
   * @memberof CulturalSurveyUserAnswer
   */
  answerIds: Array<CulturalSurveyAnswerEnum>
  /**
   * @type {CulturalSurveyQuestionEnum}
   * @memberof CulturalSurveyUserAnswer
   */
  questionId: CulturalSurveyQuestionEnum
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum CurrencyEnum {
  'EUR' = 'EUR',
  'XPF' = 'XPF',
}
/**
 * @export
 * @interface DepositAmountsByAge
 */
export interface DepositAmountsByAge {
  /**
   * @type {number}
   * @memberof DepositAmountsByAge
   */
  age_15?: number
  /**
   * @type {number}
   * @memberof DepositAmountsByAge
   */
  age_16?: number
  /**
   * @type {number}
   * @memberof DepositAmountsByAge
   */
  age_17?: number
  /**
   * @type {number}
   * @memberof DepositAmountsByAge
   */
  age_18?: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum DepositType {
  'GRANT_15_17' = 'GRANT_15_17',
  'GRANT_18' = 'GRANT_18',
}
/**
 * @export
 * @interface DomainsCredit
 */
export interface DomainsCredit {
  /**
   * @type {Credit}
   * @memberof DomainsCredit
   */
  all: Credit
  /**
   * @type {Credit}
   * @memberof DomainsCredit
   */
  digital?: Credit | null
  /**
   * @type {Credit}
   * @memberof DomainsCredit
   */
  physical?: Credit | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum EligibilityType {
  'underage' = 'underage',
  'age-18' = 'age-18',
}
/**
 * @export
 * @interface EmailChangeConfirmationResponse
 */
export interface EmailChangeConfirmationResponse {
  /**
   * @type {string}
   * @memberof EmailChangeConfirmationResponse
   */
  accessToken: string
  /**
   * @type {string}
   * @memberof EmailChangeConfirmationResponse
   */
  newEmailSelectionToken: string
  /**
   * @type {string}
   * @memberof EmailChangeConfirmationResponse
   */
  refreshToken: string
  /**
   * @type {string}
   * @memberof EmailChangeConfirmationResponse
   */
  resetPasswordToken?: string | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum EmailHistoryEventTypeEnum {
  'UPDATE_REQUEST' = 'UPDATE_REQUEST',
  'CONFIRMATION' = 'CONFIRMATION',
  'CANCELLATION' = 'CANCELLATION',
  'NEW_EMAIL_SELECTION' = 'NEW_EMAIL_SELECTION',
  'VALIDATION' = 'VALIDATION',
  'ADMIN_VALIDATION' = 'ADMIN_VALIDATION',
  'ADMIN_UPDATE_REQUEST' = 'ADMIN_UPDATE_REQUEST',
  'ADMIN_UPDATE' = 'ADMIN_UPDATE',
}
/**
 * @export
 * @interface EmailUpdateStatus
 */
export interface EmailUpdateStatus {
  /**
   * @type {boolean}
   * @memberof EmailUpdateStatus
   */
  expired: boolean
  /**
   * @type {string}
   * @memberof EmailUpdateStatus
   */
  newEmail: string
  /**
   * @type {EmailHistoryEventTypeEnum}
   * @memberof EmailUpdateStatus
   */
  status: EmailHistoryEventTypeEnum
}
/**
 * @export
 * @interface EmailUpdateStatusResponse
 */
export interface EmailUpdateStatusResponse {
  /**
   * @type {boolean}
   * @memberof EmailUpdateStatusResponse
   */
  expired: boolean
  /**
   * @type {boolean}
   * @memberof EmailUpdateStatusResponse
   */
  hasRecentlyResetPassword: boolean
  /**
   * @type {string}
   * @memberof EmailUpdateStatusResponse
   */
  newEmail?: string | null
  /**
   * @type {string}
   * @memberof EmailUpdateStatusResponse
   */
  resetPasswordToken?: string | null
  /**
   * @type {EmailHistoryEventTypeEnum}
   * @memberof EmailUpdateStatusResponse
   */
  status: EmailHistoryEventTypeEnum
  /**
   * @type {string}
   * @memberof EmailUpdateStatusResponse
   */
  token?: string | null
}
/**
 * @export
 * @interface EmailValidationRemainingResendsResponse
 */
export interface EmailValidationRemainingResendsResponse {
  /**
   * @type {string}
   * @memberof EmailValidationRemainingResendsResponse
   */
  counterResetDatetime?: string | null
  /**
   * @type {number}
   * @memberof EmailValidationRemainingResendsResponse
   */
  remainingResends: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ExpenseDomain {
  'all' = 'all',
  'digital' = 'digital',
  'physical' = 'physical',
}
/**
 * @export
 * @interface ExternalAccessibilityDataModel
 */
export interface ExternalAccessibilityDataModel {
  /**
   * @type {AudioDisabilityModel}
   * @memberof ExternalAccessibilityDataModel
   */
  audioDisability?: AudioDisabilityModel
  /**
   * @type {boolean}
   * @memberof ExternalAccessibilityDataModel
   */
  isAccessibleAudioDisability?: boolean
  /**
   * @type {boolean}
   * @memberof ExternalAccessibilityDataModel
   */
  isAccessibleMentalDisability?: boolean
  /**
   * @type {boolean}
   * @memberof ExternalAccessibilityDataModel
   */
  isAccessibleMotorDisability?: boolean
  /**
   * @type {boolean}
   * @memberof ExternalAccessibilityDataModel
   */
  isAccessibleVisualDisability?: boolean
  /**
   * @type {MentalDisabilityModel}
   * @memberof ExternalAccessibilityDataModel
   */
  mentalDisability?: MentalDisabilityModel
  /**
   * @type {MotorDisabilityModel}
   * @memberof ExternalAccessibilityDataModel
   */
  motorDisability?: MotorDisabilityModel
  /**
   * @type {VisualDisabilityModel}
   * @memberof ExternalAccessibilityDataModel
   */
  visualDisability?: VisualDisabilityModel
}
/**
 * @export
 * @interface ExternalBookingResponse
 */
export interface ExternalBookingResponse {
  /**
   * @type {string}
   * @memberof ExternalBookingResponse
   */
  barcode: string
  /**
   * @type {string}
   * @memberof ExternalBookingResponse
   */
  seat?: string | null
}
/**
 * @export
 * @interface FavoriteMediationResponse
 */
export interface FavoriteMediationResponse {
  /**
   * @type {string}
   * @memberof FavoriteMediationResponse
   */
  credit?: string | null
  /**
   * @type {string}
   * @memberof FavoriteMediationResponse
   */
  url: string
}
/**
 * @export
 * @interface FavoriteOfferResponse
 */
export interface FavoriteOfferResponse {
  /**
   * @type {Coordinates}
   * @memberof FavoriteOfferResponse
   */
  coordinates: Coordinates
  /**
   * @type {string}
   * @memberof FavoriteOfferResponse
   */
  date?: string | null
  /**
   * @type {Array<ExpenseDomain>}
   * @memberof FavoriteOfferResponse
   */
  expenseDomains: Array<ExpenseDomain>
  /**
   * @type {string}
   * @memberof FavoriteOfferResponse
   */
  externalTicketOfficeUrl?: string | null
  /**
   * @type {number}
   * @memberof FavoriteOfferResponse
   */
  id: number
  /**
   * @type {FavoriteMediationResponse}
   * @memberof FavoriteOfferResponse
   */
  image?: FavoriteMediationResponse | null
  /**
   * @type {boolean}
   * @memberof FavoriteOfferResponse
   */
  isExpired?: boolean
  /**
   * @type {boolean}
   * @memberof FavoriteOfferResponse
   */
  isReleased: boolean
  /**
   * @type {boolean}
   * @memberof FavoriteOfferResponse
   */
  isSoldOut?: boolean
  /**
   * @type {string}
   * @memberof FavoriteOfferResponse
   */
  name: string
  /**
   * @type {number}
   * @memberof FavoriteOfferResponse
   */
  price?: number | null
  /**
   * @type {string}
   * @memberof FavoriteOfferResponse
   */
  startDate?: string | null
  /**
   * @type {number}
   * @memberof FavoriteOfferResponse
   */
  startPrice?: number | null
  /**
   * @type {SubcategoryIdEnum}
   * @memberof FavoriteOfferResponse
   */
  subcategoryId: SubcategoryIdEnum
  /**
   * @type {string}
   * @memberof FavoriteOfferResponse
   */
  venueName: string
}
/**
 * @export
 * @interface FavoriteRequest
 */
export interface FavoriteRequest {
  /**
   * @type {number}
   * @memberof FavoriteRequest
   */
  offerId: number
}
/**
 * @export
 * @interface FavoriteResponse
 */
export interface FavoriteResponse {
  /**
   * @type {number}
   * @memberof FavoriteResponse
   */
  id: number
  /**
   * @type {FavoriteOfferResponse}
   * @memberof FavoriteResponse
   */
  offer: FavoriteOfferResponse
}
/**
 * @export
 * @interface FavoritesCountResponse
 */
export interface FavoritesCountResponse {
  /**
   * @type {number}
   * @memberof FavoritesCountResponse
   */
  count: number
}
/**
 * @export
 * @interface GTL
 */
export interface GTL {
  /**
   * @type {string}
   * @memberof GTL
   */
  code: string
  /**
   * @type {string}
   * @memberof GTL
   */
  label: string
  /**
   * @type {number}
   * @memberof GTL
   */
  level: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum GenreType {
  'BOOK' = 'BOOK',
  'MUSIC' = 'MUSIC',
  'SHOW' = 'SHOW',
  'MOVIE' = 'MOVIE',
}
/**
 * @export
 * @interface GenreTypeContentModel
 */
export interface GenreTypeContentModel {
  /**
   * @type {string}
   * @memberof GenreTypeContentModel
   */
  name: string
  /**
   * @type {string}
   * @memberof GenreTypeContentModel
   */
  value: string
}
/**
 * @export
 * @interface GenreTypeModel
 */
export interface GenreTypeModel {
  /**
   * @type {GenreType}
   * @memberof GenreTypeModel
   */
  name: GenreType
  /**
   * @type {Array<BookType> | Array<MusicType> | Array<ShowType> | Array<MovieType>}
   * @memberof GenreTypeModel
   */
  trees: Array<BookType> | Array<MusicType> | Array<ShowType> | Array<MovieType>
  /**
   * @type {Array<GenreTypeContentModel>}
   * @memberof GenreTypeModel
   */
  values: Array<GenreTypeContentModel>
}
/**
 * @export
 * @interface GetAvailableReactionsResponse
 */
export interface GetAvailableReactionsResponse {
  /**
   * @type {Array<AvailableReactionBooking>}
   * @memberof GetAvailableReactionsResponse
   */
  bookings: Array<AvailableReactionBooking>
  /**
   * @type {number}
   * @memberof GetAvailableReactionsResponse
   */
  numberOfReactableBookings: number
}
/**
 * @export
 * @interface GoogleAccountRequest
 */
export interface GoogleAccountRequest {
  /**
   * @type {string}
   * @memberof GoogleAccountRequest
   */
  accountCreationToken: string
  /**
   * @type {string}
   * @memberof GoogleAccountRequest
   */
  appsFlyerPlatform?: string | null
  /**
   * @type {string}
   * @memberof GoogleAccountRequest
   */
  appsFlyerUserId?: string | null
  /**
   * @type {string}
   * @memberof GoogleAccountRequest
   */
  birthdate: string
  /**
   * @type {string}
   * @memberof GoogleAccountRequest
   */
  firebasePseudoId?: string | null
  /**
   * @type {boolean}
   * @memberof GoogleAccountRequest
   */
  marketingEmailSubscription?: boolean | null
  /**
   * @type {string}
   * @memberof GoogleAccountRequest
   */
  token: string
  /**
   * @type {TrustedDevice}
   * @memberof GoogleAccountRequest
   */
  trustedDevice?: TrustedDevice | null
}
/**
 * @export
 * @interface GoogleSigninRequest
 */
export interface GoogleSigninRequest {
  /**
   * @type {string}
   * @memberof GoogleSigninRequest
   */
  authorizationCode: string
  /**
   * @type {TrustedDevice}
   * @memberof GoogleSigninRequest
   */
  deviceInfo?: TrustedDevice | null
  /**
   * @type {string}
   * @memberof GoogleSigninRequest
   */
  oauthStateToken: string
}
/**
 * @export
 * @interface GtlLabels
 */
export interface GtlLabels {
  /**
   * @type {string}
   * @memberof GtlLabels
   */
  label: string
  /**
   * @type {string}
   * @memberof GtlLabels
   */
  level01Label?: string | null
  /**
   * @type {string}
   * @memberof GtlLabels
   */
  level02Label?: string | null
  /**
   * @type {string}
   * @memberof GtlLabels
   */
  level03Label?: string | null
  /**
   * @type {string}
   * @memberof GtlLabels
   */
  level04Label?: string | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum HomepageLabelNameEnumv2 {
  'BEAUX_ARTS' = 'BEAUX_ARTS',
  'CARTE_JEUNES' = 'CARTE_JEUNES',
  'CINEMA' = 'CINEMA',
  'CONCERT' = 'CONCERT',
  'COURS' = 'COURS',
  'FESTIVAL' = 'FESTIVAL',
  'FILMS' = 'FILMS',
  'INSTRUMENT' = 'INSTRUMENT',
  'JEUX' = 'JEUX',
  'LIVRES' = 'LIVRES',
  'MEDIAS' = 'MEDIAS',
  'MUSEE' = 'MUSEE',
  'MUSIQUE' = 'MUSIQUE',
  'NONE' = 'NONE',
  'PLATEFORME' = 'PLATEFORME',
  'RENCONTRES' = 'RENCONTRES',
  'SPECTACLES' = 'SPECTACLES',
  'STAGE_ATELIER' = 'STAGE_ATELIER',
  'VISITES' = 'VISITES',
}
/**
 * @export
 * @interface HomepageLabelResponseModelv2
 */
export interface HomepageLabelResponseModelv2 {
  /**
   * @type {HomepageLabelNameEnumv2}
   * @memberof HomepageLabelResponseModelv2
   */
  name: HomepageLabelNameEnumv2
  /**
   * @type {string}
   * @memberof HomepageLabelResponseModelv2
   */
  value?: string | null
}
/**
 * @export
 * @interface IdentificationSessionRequest
 */
export interface IdentificationSessionRequest {
  /**
   * @type {string}
   * @memberof IdentificationSessionRequest
   */
  redirectUrl: string
}
/**
 * @export
 * @interface IdentificationSessionResponse
 */
export interface IdentificationSessionResponse {
  /**
   * @type {string}
   * @memberof IdentificationSessionResponse
   */
  identificationUrl: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum IdentityCheckMethod {
  'educonnect' = 'educonnect',
  'ubble' = 'ubble',
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum MaintenancePageType {
  'with-dms' = 'with-dms',
  'without-dms' = 'without-dms',
}
/**
 * @export
 * @interface MarkAchievementsAsSeenRequest
 */
export interface MarkAchievementsAsSeenRequest {
  /**
   * @type {Array<number>}
   * @memberof MarkAchievementsAsSeenRequest
   */
  achievementIds: Array<number>
}
/**
 * @export
 * @interface MentalDisabilityModel
 */
export interface MentalDisabilityModel {
  /**
   * @type {string}
   * @memberof MentalDisabilityModel
   */
  trainedPersonnel?: string
}
/**
 * @export
 * @interface MotorDisabilityModel
 */
export interface MotorDisabilityModel {
  /**
   * @type {string}
   * @memberof MotorDisabilityModel
   */
  entrance?: string
  /**
   * @type {string}
   * @memberof MotorDisabilityModel
   */
  exterior?: string
  /**
   * @type {string}
   * @memberof MotorDisabilityModel
   */
  facilities?: string
  /**
   * @type {string}
   * @memberof MotorDisabilityModel
   */
  parking?: string
}
/**
 * @export
 * @interface MovieType
 */
export interface MovieType {
  /**
   * @type {string}
   * @memberof MovieType
   */
  label: string
  /**
   * @type {string}
   * @memberof MovieType
   */
  name: string
}
/**
 * @export
 * @interface MusicType
 */
export interface MusicType {
  /**
   * @type {string}
   * @memberof MusicType
   */
  label: string
  /**
   * @type {string}
   * @memberof MusicType
   */
  name: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum NativeCategoryIdEnumv2 {
  'ABO_PLATEFORME_VIDEO' = 'ABO_PLATEFORME_VIDEO',
  'ABONNEMENTS_MUSEE' = 'ABONNEMENTS_MUSEE',
  'ABONNEMENTS_SPECTACLE' = 'ABONNEMENTS_SPECTACLE',
  'ACHAT_LOCATION_INSTRUMENT' = 'ACHAT_LOCATION_INSTRUMENT',
  'ARTS_VISUELS' = 'ARTS_VISUELS',
  'AUTRES_MEDIAS' = 'AUTRES_MEDIAS',
  'BIBLIOTHEQUE_MEDIATHEQUE' = 'BIBLIOTHEQUE_MEDIATHEQUE',
  'CARTES_CINEMA' = 'CARTES_CINEMA',
  'CD' = 'CD',
  'CONCERTS_EN_LIGNE' = 'CONCERTS_EN_LIGNE',
  'CONCERTS_EVENEMENTS' = 'CONCERTS_EVENEMENTS',
  'CONCOURS' = 'CONCOURS',
  'CONFERENCES' = 'CONFERENCES',
  'DEPRECIEE' = 'DEPRECIEE',
  'DVD_BLU_RAY' = 'DVD_BLU_RAY',
  'ESCAPE_GAMES' = 'ESCAPE_GAMES',
  'EVENEMENTS_CINEMA' = 'EVENEMENTS_CINEMA',
  'EVENEMENTS_PATRIMOINE' = 'EVENEMENTS_PATRIMOINE',
  'FESTIVALS' = 'FESTIVALS',
  'FESTIVAL_DU_LIVRE' = 'FESTIVAL_DU_LIVRE',
  'JEUX_EN_LIGNE' = 'JEUX_EN_LIGNE',
  'JEUX_PHYSIQUES' = 'JEUX_PHYSIQUES',
  'LIVRES_AUDIO_PHYSIQUES' = 'LIVRES_AUDIO_PHYSIQUES',
  'LIVRES_NUMERIQUE_ET_AUDIO' = 'LIVRES_NUMERIQUE_ET_AUDIO',
  'LIVRES_PAPIER' = 'LIVRES_PAPIER',
  'LUDOTHEQUE' = 'LUDOTHEQUE',
  'MATERIELS_CREATIFS' = 'MATERIELS_CREATIFS',
  'MUSIQUE_EN_LIGNE' = 'MUSIQUE_EN_LIGNE',
  'NATIVE_CATEGORY_NONE' = 'NATIVE_CATEGORY_NONE',
  'PARTITIONS_DE_MUSIQUE' = 'PARTITIONS_DE_MUSIQUE',
  'PODCAST' = 'PODCAST',
  'PRATIQUES_ET_ATELIERS_ARTISTIQUES' = 'PRATIQUES_ET_ATELIERS_ARTISTIQUES',
  'PRATIQUE_ARTISTIQUE_EN_LIGNE' = 'PRATIQUE_ARTISTIQUE_EN_LIGNE',
  'PRESSE_EN_LIGNE' = 'PRESSE_EN_LIGNE',
  'RENCONTRES' = 'RENCONTRES',
  'RENCONTRES_EN_LIGNE' = 'RENCONTRES_EN_LIGNE',
  'RENCONTRES_EVENEMENTS' = 'RENCONTRES_EVENEMENTS',
  'SALONS_ET_METIERS' = 'SALONS_ET_METIERS',
  'SEANCES_DE_CINEMA' = 'SEANCES_DE_CINEMA',
  'SPECTACLES_ENREGISTRES' = 'SPECTACLES_ENREGISTRES',
  'SPECTACLES_REPRESENTATIONS' = 'SPECTACLES_REPRESENTATIONS',
  'VIDEOS_ET_DOCUMENTAIRES' = 'VIDEOS_ET_DOCUMENTAIRES',
  'VINYLES' = 'VINYLES',
  'VISITES_CULTURELLES' = 'VISITES_CULTURELLES',
  'VISITES_CULTURELLES_EN_LIGNE' = 'VISITES_CULTURELLES_EN_LIGNE',
}
/**
 * @export
 * @interface NativeCategoryResponseModelv2
 */
export interface NativeCategoryResponseModelv2 {
  /**
   * @type {GenreType}
   * @memberof NativeCategoryResponseModelv2
   */
  genreType?: GenreType | null
  /**
   * @type {NativeCategoryIdEnumv2}
   * @memberof NativeCategoryResponseModelv2
   */
  name: NativeCategoryIdEnumv2
  /**
   * @type {Array<SearchGroupNameEnumv2>}
   * @memberof NativeCategoryResponseModelv2
   */
  parents: Array<SearchGroupNameEnumv2>
  /**
   * @type {{ [key: string]: number; }}
   * @memberof NativeCategoryResponseModelv2
   */
  positions?: { [key: string]: number } | null
  /**
   * @type {string}
   * @memberof NativeCategoryResponseModelv2
   */
  value?: string | null
}
/**
 * @export
 * @interface NewEmailSelectionRequest
 */
export interface NewEmailSelectionRequest {
  /**
   * @type {string}
   * @memberof NewEmailSelectionRequest
   */
  newEmail: string
  /**
   * @type {string}
   * @memberof NewEmailSelectionRequest
   */
  token: string
}
/**
 * @export
 * @interface NextSubscriptionStepResponse
 */
export interface NextSubscriptionStepResponse {
  /**
   * @type {Array<IdentityCheckMethod>}
   * @memberof NextSubscriptionStepResponse
   */
  allowedIdentityCheckMethods: Array<IdentityCheckMethod>
  /**
   * @type {boolean}
   * @memberof NextSubscriptionStepResponse
   */
  hasIdentityCheckPending: boolean
  /**
   * @type {MaintenancePageType}
   * @memberof NextSubscriptionStepResponse
   */
  maintenancePageType?: MaintenancePageType | null
  /**
   * @type {SubscriptionStep}
   * @memberof NextSubscriptionStepResponse
   */
  nextSubscriptionStep?: SubscriptionStep | null
  /**
   * @type {SubscriptionMessage}
   * @memberof NextSubscriptionStepResponse
   */
  subscriptionMessage?: SubscriptionMessage | null
}
/**
 * @export
 * @interface NotificationSubscriptions
 */
export interface NotificationSubscriptions {
  /**
   * @type {boolean}
   * @memberof NotificationSubscriptions
   */
  marketingEmail: boolean
  /**
   * @type {boolean}
   * @memberof NotificationSubscriptions
   */
  marketingPush: boolean
  /**
   * @type {Array<string>}
   * @memberof NotificationSubscriptions
   */
  subscribedThemes?: Array<string>
}
/**
 * @export
 * @interface OauthStateResponse
 */
export interface OauthStateResponse {
  /**
   * @type {string}
   * @memberof OauthStateResponse
   */
  oauthStateToken: string
}
/**
 * @export
 * @interface OfferAccessibilityResponse
 */
export interface OfferAccessibilityResponse {
  /**
   * @type {boolean}
   * @memberof OfferAccessibilityResponse
   */
  audioDisability?: boolean | null
  /**
   * @type {boolean}
   * @memberof OfferAccessibilityResponse
   */
  mentalDisability?: boolean | null
  /**
   * @type {boolean}
   * @memberof OfferAccessibilityResponse
   */
  motorDisability?: boolean | null
  /**
   * @type {boolean}
   * @memberof OfferAccessibilityResponse
   */
  visualDisability?: boolean | null
}
/**
 * @export
 * @interface OfferAddressResponse
 */
export interface OfferAddressResponse {
  /**
   * @type {string}
   * @memberof OfferAddressResponse
   */
  city: string
  /**
   * @type {Coordinates}
   * @memberof OfferAddressResponse
   */
  coordinates: Coordinates
  /**
   * @type {string}
   * @memberof OfferAddressResponse
   */
  label?: string | null
  /**
   * @type {string}
   * @memberof OfferAddressResponse
   */
  postalCode: string
  /**
   * @type {string}
   * @memberof OfferAddressResponse
   */
  street?: string | null
  /**
   * @type {string}
   * @memberof OfferAddressResponse
   */
  timezone: string
}
/**
 * @export
 * @interface OfferChronicle
 */
export interface OfferChronicle {
  /**
   * @type {ChronicleAuthor}
   * @memberof OfferChronicle
   */
  author?: ChronicleAuthor | null
  /**
   * @type {string}
   * @memberof OfferChronicle
   */
  content: string
  /**
   * @type {string}
   * @memberof OfferChronicle
   */
  dateCreated: string
  /**
   * @type {number}
   * @memberof OfferChronicle
   */
  id: number
}
/**
 * @export
 * @interface OfferChronicles
 */
export interface OfferChronicles {
  /**
   * @type {Array<OfferChronicle>}
   * @memberof OfferChronicles
   */
  chronicles: Array<OfferChronicle>
}
/**
 * @export
 * @interface OfferExtraDataResponse
 */
export interface OfferExtraDataResponse {
  /**
   * @type {number}
   * @memberof OfferExtraDataResponse
   */
  allocineId?: number | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  author?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  bookFormat?: string | null
  /**
   * @type {Array<string>}
   * @memberof OfferExtraDataResponse
   */
  cast?: Array<string> | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  certificate?: string | null
  /**
   * @type {number}
   * @memberof OfferExtraDataResponse
   */
  durationMinutes?: number | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  ean?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  editeur?: string | null
  /**
   * @type {Array<string>}
   * @memberof OfferExtraDataResponse
   */
  genres?: Array<string> | null
  /**
   * @type {GtlLabels}
   * @memberof OfferExtraDataResponse
   */
  gtlLabels?: GtlLabels | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  musicSubType?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  musicType?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  performer?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  releaseDate?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  showSubType?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  showType?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  speaker?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  stageDirector?: string | null
  /**
   * @type {string}
   * @memberof OfferExtraDataResponse
   */
  visa?: string | null
}
/**
 * @export
 * @interface OfferImage
 */
export interface OfferImage {
  /**
   * @type {string}
   * @memberof OfferImage
   */
  credit?: string
  /**
   * @type {string}
   * @memberof OfferImage
   */
  url: string
}
/**
 * @export
 * @interface OfferImageResponse
 */
export interface OfferImageResponse {
  /**
   * @type {string}
   * @memberof OfferImageResponse
   */
  credit?: string | null
  /**
   * @type {string}
   * @memberof OfferImageResponse
   */
  url: string
}
/**
 * @export
 * @interface OfferOffererResponse
 */
export interface OfferOffererResponse {
  /**
   * @type {string}
   * @memberof OfferOffererResponse
   */
  name: string
}
/**
 * @export
 * @interface OfferPreviewResponse
 */
export interface OfferPreviewResponse {
  /**
   * @type {number}
   * @memberof OfferPreviewResponse
   */
  durationMinutes?: number | null
  /**
   * @type {OfferExtraDataResponse}
   * @memberof OfferPreviewResponse
   */
  extraData?: OfferExtraDataResponse | null
  /**
   * @type {number}
   * @memberof OfferPreviewResponse
   */
  id: number
  /**
   * @type {OfferImageResponse}
   * @memberof OfferPreviewResponse
   */
  image?: OfferImageResponse | null
  /**
   * @type {number}
   * @memberof OfferPreviewResponse
   */
  last30DaysBookings?: number | null
  /**
   * @type {string}
   * @memberof OfferPreviewResponse
   */
  name: string
  /**
   * @type {Array<OfferStockResponse>}
   * @memberof OfferPreviewResponse
   */
  stocks: Array<OfferStockResponse>
}
/**
 * @export
 * @interface OfferReportReasons
 */
export interface OfferReportReasons {
  /**
   * @type {{ [key: string]: ReasonMeta; }}
   * @memberof OfferReportReasons
   */
  reasons: { [key: string]: ReasonMeta }
}
/**
 * @export
 * @interface OfferReportRequest
 */
export interface OfferReportRequest {
  /**
   * @type {string}
   * @memberof OfferReportRequest
   */
  customReason?: string | null
  /**
   * @type {Reason}
   * @memberof OfferReportRequest
   */
  reason: Reason
}
/**
 * @export
 * @interface OfferResponse
 */
export interface OfferResponse {
  /**
   * @type {OfferAccessibilityResponse}
   * @memberof OfferResponse
   */
  accessibility: OfferAccessibilityResponse
  /**
   * @type {OfferAddressResponse}
   * @memberof OfferResponse
   */
  address?: OfferAddressResponse | null
  /**
   * @type {Array<ChroniclePreview>}
   * @memberof OfferResponse
   */
  chronicles: Array<ChroniclePreview>
  /**
   * @type {string}
   * @memberof OfferResponse
   */
  description?: string | null
  /**
   * @type {Array<ExpenseDomain>}
   * @memberof OfferResponse
   */
  expenseDomains: Array<ExpenseDomain>
  /**
   * @type {string}
   * @memberof OfferResponse
   */
  externalTicketOfficeUrl?: string | null
  /**
   * @type {OfferExtraDataResponse}
   * @memberof OfferResponse
   */
  extraData?: OfferExtraDataResponse | null
  /**
   * @type {number}
   * @memberof OfferResponse
   */
  id: number
  /**
   * @type {OfferImageResponse}
   * @memberof OfferResponse
   */
  image?: OfferImageResponse | null
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isDigital: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isDuo: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isEducational: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isExpired: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isExternalBookingsDisabled: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isForbiddenToUnderage: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isHeadline: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isReleased: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponse
   */
  isSoldOut: boolean
  /**
   * @type {number}
   * @memberof OfferResponse
   */
  last30DaysBookings?: number | null
  /**
   * @type {any}
   * @memberof OfferResponse
   */
  metadata: any
  /**
   * @type {string}
   * @memberof OfferResponse
   */
  name: string
  /**
   * @type {string}
   * @memberof OfferResponse
   */
  publicationDate?: string | null
  /**
   * @type {ReactionCount}
   * @memberof OfferResponse
   */
  reactionsCount: ReactionCount
  /**
   * @type {Array<OfferStockResponse>}
   * @memberof OfferResponse
   */
  stocks: Array<OfferStockResponse>
  /**
   * @type {SubcategoryIdEnum}
   * @memberof OfferResponse
   */
  subcategoryId: SubcategoryIdEnum
  /**
   * @type {OfferVenueResponse}
   * @memberof OfferResponse
   */
  venue: OfferVenueResponse
  /**
   * @type {string}
   * @memberof OfferResponse
   */
  withdrawalDetails?: string | null
}
/**
 * @export
 * @interface OfferResponseV2
 */
export interface OfferResponseV2 {
  /**
   * @type {OfferAccessibilityResponse}
   * @memberof OfferResponseV2
   */
  accessibility: OfferAccessibilityResponse
  /**
   * @type {OfferAddressResponse}
   * @memberof OfferResponseV2
   */
  address?: OfferAddressResponse | null
  /**
   * @type {Array<ChroniclePreview>}
   * @memberof OfferResponseV2
   */
  chronicles: Array<ChroniclePreview>
  /**
   * @type {string}
   * @memberof OfferResponseV2
   */
  description?: string | null
  /**
   * @type {Array<ExpenseDomain>}
   * @memberof OfferResponseV2
   */
  expenseDomains: Array<ExpenseDomain>
  /**
   * @type {string}
   * @memberof OfferResponseV2
   */
  externalTicketOfficeUrl?: string | null
  /**
   * @type {OfferExtraDataResponse}
   * @memberof OfferResponseV2
   */
  extraData?: OfferExtraDataResponse | null
  /**
   * @type {number}
   * @memberof OfferResponseV2
   */
  id: number
  /**
   * @type {{ [key: string]: OfferImageResponse; }}
   * @memberof OfferResponseV2
   */
  images?: { [key: string]: OfferImageResponse } | null
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isDigital: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isDuo: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isEducational: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isExpired: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isExternalBookingsDisabled: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isForbiddenToUnderage: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isHeadline: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isReleased: boolean
  /**
   * @type {boolean}
   * @memberof OfferResponseV2
   */
  isSoldOut: boolean
  /**
   * @type {number}
   * @memberof OfferResponseV2
   */
  last30DaysBookings?: number | null
  /**
   * @type {any}
   * @memberof OfferResponseV2
   */
  metadata: any
  /**
   * @type {string}
   * @memberof OfferResponseV2
   */
  name: string
  /**
   * @type {string}
   * @memberof OfferResponseV2
   */
  publicationDate?: string | null
  /**
   * @type {ReactionCount}
   * @memberof OfferResponseV2
   */
  reactionsCount: ReactionCount
  /**
   * @type {Array<OfferStockResponse>}
   * @memberof OfferResponseV2
   */
  stocks: Array<OfferStockResponse>
  /**
   * @type {SubcategoryIdEnum}
   * @memberof OfferResponseV2
   */
  subcategoryId: SubcategoryIdEnum
  /**
   * @type {OfferVenueResponse}
   * @memberof OfferResponseV2
   */
  venue: OfferVenueResponse
  /**
   * @type {string}
   * @memberof OfferResponseV2
   */
  withdrawalDetails?: string | null
}
/**
 * @export
 * @interface OfferStockActivationCodeResponse
 */
export interface OfferStockActivationCodeResponse {
  /**
   * @type {string}
   * @memberof OfferStockActivationCodeResponse
   */
  expirationDate?: string | null
}
/**
 * @export
 * @interface OfferStockResponse
 */
export interface OfferStockResponse {
  /**
   * @type {OfferStockActivationCodeResponse}
   * @memberof OfferStockResponse
   */
  activationCode?: OfferStockActivationCodeResponse | null
  /**
   * @type {string}
   * @memberof OfferStockResponse
   */
  beginningDatetime?: string | null
  /**
   * @type {string}
   * @memberof OfferStockResponse
   */
  bookingLimitDatetime?: string | null
  /**
   * @type {string}
   * @memberof OfferStockResponse
   */
  cancellationLimitDatetime?: string | null
  /**
   * @type {Array<string>}
   * @memberof OfferStockResponse
   */
  features: Array<string>
  /**
   * @type {number}
   * @memberof OfferStockResponse
   */
  id: number
  /**
   * @type {boolean}
   * @memberof OfferStockResponse
   */
  isBookable: boolean
  /**
   * @type {boolean}
   * @memberof OfferStockResponse
   */
  isExpired: boolean
  /**
   * @type {boolean}
   * @memberof OfferStockResponse
   */
  isForbiddenToUnderage: boolean
  /**
   * @type {boolean}
   * @memberof OfferStockResponse
   */
  isSoldOut: boolean
  /**
   * @type {number}
   * @memberof OfferStockResponse
   */
  price: number
  /**
   * @type {string}
   * @memberof OfferStockResponse
   */
  priceCategoryLabel?: string | null
  /**
   * @type {number}
   * @memberof OfferStockResponse
   */
  remainingQuantity?: number | null
}
/**
 * @export
 * @interface OfferVenueResponse
 */
export interface OfferVenueResponse {
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  address?: string | null
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  bannerUrl?: string | null
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  city?: string | null
  /**
   * @type {Coordinates}
   * @memberof OfferVenueResponse
   */
  coordinates: Coordinates
  /**
   * @type {number}
   * @memberof OfferVenueResponse
   */
  id: number
  /**
   * @type {boolean}
   * @memberof OfferVenueResponse
   */
  isOpenToPublic?: boolean | null
  /**
   * @type {boolean}
   * @memberof OfferVenueResponse
   */
  isPermanent: boolean
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  name: string
  /**
   * @type {OfferOffererResponse}
   * @memberof OfferVenueResponse
   */
  offerer: OfferOffererResponse
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  postalCode?: string | null
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  publicName?: string | null
  /**
   * @type {string}
   * @memberof OfferVenueResponse
   */
  timezone: string
}
/**
 * @export
 * @interface OffererHeadLineOfferResponseModel
 */
export interface OffererHeadLineOfferResponseModel {
  /**
   * @type {number}
   * @memberof OffererHeadLineOfferResponseModel
   */
  id: number
  /**
   * @type {OfferImage}
   * @memberof OffererHeadLineOfferResponseModel
   */
  image?: OfferImage | null
  /**
   * @type {string}
   * @memberof OffererHeadLineOfferResponseModel
   */
  name: string
}
/**
 * @export
 * @interface OffersStocksRequest
 */
export interface OffersStocksRequest {
  /**
   * @type {Array<number>}
   * @memberof OffersStocksRequest
   */
  offer_ids: Array<number>
}
/**
 * @export
 * @interface OffersStocksResponse
 */
export interface OffersStocksResponse {
  /**
   * @type {Array<OfferPreviewResponse>}
   * @memberof OffersStocksResponse
   */
  offers: Array<OfferPreviewResponse>
}
/**
 * @export
 * @interface OffersStocksResponseV2
 */
export interface OffersStocksResponseV2 {
  /**
   * @type {Array<OfferResponseV2>}
   * @memberof OffersStocksResponseV2
   */
  offers: Array<OfferResponseV2>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum OnlineOfflinePlatformChoicesEnum {
  'OFFLINE' = 'OFFLINE',
  'ONLINE' = 'ONLINE',
  'ONLINE_OR_OFFLINE' = 'ONLINE_OR_OFFLINE',
}
/**
 * @export
 * @interface PaginatedFavoritesResponse
 */
export interface PaginatedFavoritesResponse {
  /**
   * @type {Array<FavoriteResponse>}
   * @memberof PaginatedFavoritesResponse
   */
  favorites: Array<FavoriteResponse>
  /**
   * @type {number}
   * @memberof PaginatedFavoritesResponse
   */
  nbFavorites: number
  /**
   * @type {number}
   * @memberof PaginatedFavoritesResponse
   */
  page: number
}
/**
 * @export
 * @interface PhoneValidationRemainingAttemptsRequest
 */
export interface PhoneValidationRemainingAttemptsRequest {
  /**
   * @type {string}
   * @memberof PhoneValidationRemainingAttemptsRequest
   */
  counterResetDatetime?: string | null
  /**
   * @type {number}
   * @memberof PhoneValidationRemainingAttemptsRequest
   */
  remainingAttempts: number
}
/**
 * @export
 * @interface PlaylistRequestBody
 */
export interface PlaylistRequestBody {
  /**
   * @type {Array<string>}
   * @memberof PlaylistRequestBody
   */
  categories?: Array<string> | null
  /**
   * @type {string}
   * @memberof PlaylistRequestBody
   */
  endDate?: string | null
  /**
   * @type {boolean}
   * @memberof PlaylistRequestBody
   */
  isDuo?: boolean | null
  /**
   * @type {boolean}
   * @memberof PlaylistRequestBody
   */
  isEvent?: boolean | null
  /**
   * @type {boolean}
   * @memberof PlaylistRequestBody
   */
  isRecoShuffled?: boolean | null
  /**
   * @type {Array<{ [key: string]: string; }>}
   * @memberof PlaylistRequestBody
   */
  offerTypeList?: Array<{ [key: string]: string }> | null
  /**
   * @type {number}
   * @memberof PlaylistRequestBody
   */
  priceMax?: number | null
  /**
   * @type {number}
   * @memberof PlaylistRequestBody
   */
  priceMin?: number | null
  /**
   * @type {string}
   * @memberof PlaylistRequestBody
   */
  startDate?: string | null
  /**
   * @type {Array<string>}
   * @memberof PlaylistRequestBody
   */
  subcategories?: Array<string> | null
}
/**
 * @export
 * @interface PlaylistRequestQuery
 */
export interface PlaylistRequestQuery {
  /**
   * @type {number}
   * @memberof PlaylistRequestQuery
   */
  latitude?: number | null
  /**
   * @type {number}
   * @memberof PlaylistRequestQuery
   */
  longitude?: number | null
  /**
   * @type {string}
   * @memberof PlaylistRequestQuery
   */
  modelEndpoint?: string | null
}
/**
 * @export
 * @interface PlaylistResponse
 */
export interface PlaylistResponse {
  /**
   * @type {RecommendationApiParams}
   * @memberof PlaylistResponse
   */
  params: RecommendationApiParams
  /**
   * @type {Array<string>}
   * @memberof PlaylistResponse
   */
  playlistRecommendedOffers: Array<string>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum PopOverIcon {
  'INFO' = 'INFO',
  'ERROR' = 'ERROR',
  'WARNING' = 'WARNING',
  'CLOCK' = 'CLOCK',
  'FILE' = 'FILE',
  'MAGNIFYING_GLASS' = 'MAGNIFYING_GLASS',
}
/**
 * @export
 * @interface PostFeedbackBody
 */
export interface PostFeedbackBody {
  /**
   * @type {string}
   * @memberof PostFeedbackBody
   */
  feedback: string
}
/**
 * @export
 * @interface PostOneReactionRequest
 */
export interface PostOneReactionRequest {
  /**
   * @type {number}
   * @memberof PostOneReactionRequest
   */
  offerId: number
  /**
   * @type {ReactionTypeEnum}
   * @memberof PostOneReactionRequest
   */
  reactionType: ReactionTypeEnum
}
/**
 * @export
 * @interface PostReactionRequest
 */
export interface PostReactionRequest {
  /**
   * @type {Array<PostOneReactionRequest>}
   * @memberof PostReactionRequest
   */
  reactions: Array<PostOneReactionRequest>
}
/**
 * @export
 * @interface ProfileUpdateRequest
 */
export interface ProfileUpdateRequest {
  /**
   * @type {ActivityIdEnum}
   * @memberof ProfileUpdateRequest
   */
  activityId: ActivityIdEnum
  /**
   * @type {string}
   * @memberof ProfileUpdateRequest
   */
  address: string
  /**
   * @type {string}
   * @memberof ProfileUpdateRequest
   */
  city: string
  /**
   * @type {string}
   * @memberof ProfileUpdateRequest
   */
  firstName: string
  /**
   * @type {string}
   * @memberof ProfileUpdateRequest
   */
  lastName: string
  /**
   * @type {string}
   * @memberof ProfileUpdateRequest
   */
  postalCode: string
  /**
   * @type {SchoolTypesIdEnum}
   * @memberof ProfileUpdateRequest
   */
  schoolTypeId?: SchoolTypesIdEnum | null
}
/**
 * @export
 * @interface Rates
 */
export interface Rates {
  /**
   * @type {number}
   * @memberof Rates
   */
  pacificFrancToEuro?: number
}
/**
 * @export
 * @interface ReactionCount
 */
export interface ReactionCount {
  /**
   * @type {number}
   * @memberof ReactionCount
   */
  likes: number
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum ReactionTypeEnum {
  'LIKE' = 'LIKE',
  'DISLIKE' = 'DISLIKE',
  'NO_REACTION' = 'NO_REACTION',
}
/**
 * Describe possible reason codes to used when reporting an offer.  The whole meta part is only consumed by the api client, it has no meaning inside the whole API code.  Note: when adding a new enum symbol, do not forget to update the meta method.
 * @export
 * @enum {string}
 */
export enum Reason {
  'IMPROPER' = 'IMPROPER',
  'PRICE_TOO_HIGH' = 'PRICE_TOO_HIGH',
  'INAPPROPRIATE' = 'INAPPROPRIATE',
  'OTHER' = 'OTHER',
}
/**
 * @export
 * @interface ReasonMeta
 */
export interface ReasonMeta {
  /**
   * @type {string}
   * @memberof ReasonMeta
   */
  description: string
  /**
   * @type {string}
   * @memberof ReasonMeta
   */
  title: string
}
/**
 * @export
 * @interface RecommendationApiParams
 */
export interface RecommendationApiParams {
  /**
   * @type {string}
   * @memberof RecommendationApiParams
   */
  ab_test?: string | null
  /**
   * @type {string}
   * @memberof RecommendationApiParams
   */
  call_id?: string | null
  /**
   * @type {boolean}
   * @memberof RecommendationApiParams
   */
  filtered?: boolean | null
  /**
   * @type {boolean}
   * @memberof RecommendationApiParams
   */
  geo_located?: boolean | null
  /**
   * @type {string}
   * @memberof RecommendationApiParams
   */
  model_endpoint?: string | null
  /**
   * @type {string}
   * @memberof RecommendationApiParams
   */
  model_name?: string | null
  /**
   * @type {string}
   * @memberof RecommendationApiParams
   */
  model_version?: string | null
  /**
   * @type {string}
   * @memberof RecommendationApiParams
   */
  reco_origin?: string | null
}
/**
 * @export
 * @interface RefreshResponse
 */
export interface RefreshResponse {
  /**
   * @type {string}
   * @memberof RefreshResponse
   */
  accessToken: string
}
/**
 * @export
 * @interface ReportedOffer
 */
export interface ReportedOffer {
  /**
   * @type {number}
   * @memberof ReportedOffer
   */
  offerId: number
  /**
   * @type {Reason}
   * @memberof ReportedOffer
   */
  reason: Reason
  /**
   * @type {string}
   * @memberof ReportedOffer
   */
  reportedAt: string
}
/**
 * @export
 * @interface RequestPasswordResetRequest
 */
export interface RequestPasswordResetRequest {
  /**
   * @type {string}
   * @memberof RequestPasswordResetRequest
   */
  email: string
  /**
   * @type {string}
   * @memberof RequestPasswordResetRequest
   */
  token?: string | null
}
/**
 * @export
 * @interface ResendEmailValidationRequest
 */
export interface ResendEmailValidationRequest {
  /**
   * @type {string}
   * @memberof ResendEmailValidationRequest
   */
  email: string
}
/**
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   * @type {TrustedDevice}
   * @memberof ResetPasswordRequest
   */
  deviceInfo?: TrustedDevice | null
  /**
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  newPassword: string
  /**
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  resetPasswordToken: string
}
/**
 * @export
 * @interface ResetPasswordResponse
 */
export interface ResetPasswordResponse {
  /**
   * @type {string}
   * @memberof ResetPasswordResponse
   */
  accessToken: string
  /**
   * @type {string}
   * @memberof ResetPasswordResponse
   */
  refreshToken: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SchoolTypesIdEnum {
  'AGRICULTURAL_HIGH_SCHOOL' = 'AGRICULTURAL_HIGH_SCHOOL',
  'APPRENTICE_FORMATION_CENTER' = 'APPRENTICE_FORMATION_CENTER',
  'MILITARY_HIGH_SCHOOL' = 'MILITARY_HIGH_SCHOOL',
  'HOME_OR_REMOTE_SCHOOLING' = 'HOME_OR_REMOTE_SCHOOLING',
  'NAVAL_HIGH_SCHOOL' = 'NAVAL_HIGH_SCHOOL',
  'PRIVATE_HIGH_SCHOOL' = 'PRIVATE_HIGH_SCHOOL',
  'PRIVATE_SECONDARY_SCHOOL' = 'PRIVATE_SECONDARY_SCHOOL',
  'PUBLIC_HIGH_SCHOOL' = 'PUBLIC_HIGH_SCHOOL',
  'PUBLIC_SECONDARY_SCHOOL' = 'PUBLIC_SECONDARY_SCHOOL',
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SearchGroupNameEnumv2 {
  'ARTS_LOISIRS_CREATIFS' = 'ARTS_LOISIRS_CREATIFS',
  'CARTES_JEUNES' = 'CARTES_JEUNES',
  'CONCERTS_FESTIVALS' = 'CONCERTS_FESTIVALS',
  'EVENEMENTS_EN_LIGNE' = 'EVENEMENTS_EN_LIGNE',
  'CINEMA' = 'CINEMA',
  'FILMS_DOCUMENTAIRES_SERIES' = 'FILMS_DOCUMENTAIRES_SERIES',
  'JEUX_JEUX_VIDEOS' = 'JEUX_JEUX_VIDEOS',
  'LIVRES' = 'LIVRES',
  'MEDIA_PRESSE' = 'MEDIA_PRESSE',
  'MUSEES_VISITES_CULTURELLES' = 'MUSEES_VISITES_CULTURELLES',
  'MUSIQUE' = 'MUSIQUE',
  'NONE' = 'NONE',
  'RENCONTRES_CONFERENCES' = 'RENCONTRES_CONFERENCES',
  'SPECTACLES' = 'SPECTACLES',
}
/**
 * @export
 * @interface SearchGroupResponseModelv2
 */
export interface SearchGroupResponseModelv2 {
  /**
   * @type {SearchGroupNameEnumv2}
   * @memberof SearchGroupResponseModelv2
   */
  name: SearchGroupNameEnumv2
  /**
   * @type {string}
   * @memberof SearchGroupResponseModelv2
   */
  value?: string | null
}
/**
 * @export
 * @interface SendPhoneValidationRequest
 */
export interface SendPhoneValidationRequest {
  /**
   * @type {string}
   * @memberof SendPhoneValidationRequest
   */
  phoneNumber: string
}
/**
 * @export
 * @interface SettingsResponse
 */
export interface SettingsResponse {
  /**
   * @type {number}
   * @memberof SettingsResponse
   */
  accountCreationMinimumAge: number
  /**
   * @type {number}
   * @memberof SettingsResponse
   */
  accountUnsuspensionLimit: number
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  appEnableAutocomplete: boolean
  /**
   * @type {DepositAmountsByAge}
   * @memberof SettingsResponse
   */
  depositAmountsByAge?: DepositAmountsByAge
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  displayDmsRedirection: boolean
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  enableFrontImageResizing: boolean
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  enableNativeCulturalSurvey: boolean
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  enablePhoneValidation: boolean
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  idCheckAddressAutocompletion: boolean
  /**
   * @type {Array<string>}
   * @memberof SettingsResponse
   */
  ineligiblePostalCodes: Array<string>
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  isRecaptchaEnabled: boolean
  /**
   * @type {string}
   * @memberof SettingsResponse
   */
  objectStorageUrl: string
  /**
   * @type {Rates}
   * @memberof SettingsResponse
   */
  rates?: Rates
  /**
   * @type {boolean}
   * @memberof SettingsResponse
   */
  wipEnableCreditV3: boolean
}
/**
 * @export
 * @interface ShowSubType
 */
export interface ShowSubType {
  /**
   * @type {number}
   * @memberof ShowSubType
   */
  code: number
  /**
   * @type {string}
   * @memberof ShowSubType
   */
  label: string
  /**
   * @type {string}
   * @memberof ShowSubType
   */
  slug: string
}
/**
 * @export
 * @interface ShowType
 */
export interface ShowType {
  /**
   * @type {Array<ShowSubType>}
   * @memberof ShowType
   */
  children: Array<ShowSubType>
  /**
   * @type {number}
   * @memberof ShowType
   */
  code: number
  /**
   * @type {string}
   * @memberof ShowType
   */
  label: string
}
/**
 * @export
 * @interface SigninRequest
 */
export interface SigninRequest {
  /**
   * @type {TrustedDevice}
   * @memberof SigninRequest
   */
  deviceInfo?: TrustedDevice | null
  /**
   * @type {string}
   * @memberof SigninRequest
   */
  identifier: string
  /**
   * @type {string}
   * @memberof SigninRequest
   */
  password: string
  /**
   * @type {string}
   * @memberof SigninRequest
   */
  token?: string | null
}
/**
 * @export
 * @interface SigninResponse
 */
export interface SigninResponse {
  /**
   * @type {string}
   * @memberof SigninResponse
   */
  accessToken: string
  /**
   * @type {AccountState}
   * @memberof SigninResponse
   */
  accountState: AccountState
  /**
   * @type {string}
   * @memberof SigninResponse
   */
  refreshToken: string
}
/**
 * @export
 * @interface SimilarOffersRequestQuery
 */
export interface SimilarOffersRequestQuery {
  /**
   * @type {Array<string>}
   * @memberof SimilarOffersRequestQuery
   */
  categories?: Array<string> | null
  /**
   * @type {number}
   * @memberof SimilarOffersRequestQuery
   */
  latitude?: number | null
  /**
   * @type {number}
   * @memberof SimilarOffersRequestQuery
   */
  longitude?: number | null
  /**
   * @type {Array<string>}
   * @memberof SimilarOffersRequestQuery
   */
  subcategories?: Array<string> | null
}
/**
 * @export
 * @interface SimilarOffersResponse
 */
export interface SimilarOffersResponse {
  /**
   * @type {RecommendationApiParams}
   * @memberof SimilarOffersResponse
   */
  params: RecommendationApiParams
  /**
   * @type {Array<string>}
   * @memberof SimilarOffersResponse
   */
  results?: Array<string>
}
/**
 * @export
 * @interface SubcategoriesResponseModelv2
 */
export interface SubcategoriesResponseModelv2 {
  /**
   * @type {Array<GenreTypeModel>}
   * @memberof SubcategoriesResponseModelv2
   */
  genreTypes: Array<GenreTypeModel>
  /**
   * @type {Array<HomepageLabelResponseModelv2>}
   * @memberof SubcategoriesResponseModelv2
   */
  homepageLabels: Array<HomepageLabelResponseModelv2>
  /**
   * @type {Array<NativeCategoryResponseModelv2>}
   * @memberof SubcategoriesResponseModelv2
   */
  nativeCategories: Array<NativeCategoryResponseModelv2>
  /**
   * @type {Array<SearchGroupResponseModelv2>}
   * @memberof SubcategoriesResponseModelv2
   */
  searchGroups: Array<SearchGroupResponseModelv2>
  /**
   * @type {Array<SubcategoryResponseModelv2>}
   * @memberof SubcategoriesResponseModelv2
   */
  subcategories: Array<SubcategoryResponseModelv2>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SubcategoryIdEnum {
  'ABO_BIBLIOTHEQUE' = 'ABO_BIBLIOTHEQUE',
  'ABO_CONCERT' = 'ABO_CONCERT',
  'ABO_JEU_VIDEO' = 'ABO_JEU_VIDEO',
  'ABO_LIVRE_NUMERIQUE' = 'ABO_LIVRE_NUMERIQUE',
  'ABO_LUDOTHEQUE' = 'ABO_LUDOTHEQUE',
  'ABO_MEDIATHEQUE' = 'ABO_MEDIATHEQUE',
  'ABO_PLATEFORME_MUSIQUE' = 'ABO_PLATEFORME_MUSIQUE',
  'ABO_PLATEFORME_VIDEO' = 'ABO_PLATEFORME_VIDEO',
  'ABO_PRATIQUE_ART' = 'ABO_PRATIQUE_ART',
  'ABO_PRESSE_EN_LIGNE' = 'ABO_PRESSE_EN_LIGNE',
  'ABO_SPECTACLE' = 'ABO_SPECTACLE',
  'ACHAT_INSTRUMENT' = 'ACHAT_INSTRUMENT',
  'ACTIVATION_EVENT' = 'ACTIVATION_EVENT',
  'ACTIVATION_THING' = 'ACTIVATION_THING',
  'APP_CULTURELLE' = 'APP_CULTURELLE',
  'ATELIER_PRATIQUE_ART' = 'ATELIER_PRATIQUE_ART',
  'AUTRE_SUPPORT_NUMERIQUE' = 'AUTRE_SUPPORT_NUMERIQUE',
  'BON_ACHAT_INSTRUMENT' = 'BON_ACHAT_INSTRUMENT',
  'CAPTATION_MUSIQUE' = 'CAPTATION_MUSIQUE',
  'CARTE_CINE_ILLIMITE' = 'CARTE_CINE_ILLIMITE',
  'CARTE_CINE_MULTISEANCES' = 'CARTE_CINE_MULTISEANCES',
  'CARTE_JEUNES' = 'CARTE_JEUNES',
  'CARTE_MUSEE' = 'CARTE_MUSEE',
  'CINE_PLEIN_AIR' = 'CINE_PLEIN_AIR',
  'CINE_VENTE_DISTANCE' = 'CINE_VENTE_DISTANCE',
  'CONCERT' = 'CONCERT',
  'CONCOURS' = 'CONCOURS',
  'CONFERENCE' = 'CONFERENCE',
  'DECOUVERTE_METIERS' = 'DECOUVERTE_METIERS',
  'ESCAPE_GAME' = 'ESCAPE_GAME',
  'EVENEMENT_CINE' = 'EVENEMENT_CINE',
  'EVENEMENT_JEU' = 'EVENEMENT_JEU',
  'EVENEMENT_MUSIQUE' = 'EVENEMENT_MUSIQUE',
  'EVENEMENT_PATRIMOINE' = 'EVENEMENT_PATRIMOINE',
  'FESTIVAL_ART_VISUEL' = 'FESTIVAL_ART_VISUEL',
  'FESTIVAL_CINE' = 'FESTIVAL_CINE',
  'FESTIVAL_LIVRE' = 'FESTIVAL_LIVRE',
  'FESTIVAL_MUSIQUE' = 'FESTIVAL_MUSIQUE',
  'FESTIVAL_SPECTACLE' = 'FESTIVAL_SPECTACLE',
  'JEU_EN_LIGNE' = 'JEU_EN_LIGNE',
  'JEU_SUPPORT_PHYSIQUE' = 'JEU_SUPPORT_PHYSIQUE',
  'LIVESTREAM_EVENEMENT' = 'LIVESTREAM_EVENEMENT',
  'LIVESTREAM_MUSIQUE' = 'LIVESTREAM_MUSIQUE',
  'LIVESTREAM_PRATIQUE_ARTISTIQUE' = 'LIVESTREAM_PRATIQUE_ARTISTIQUE',
  'LIVRE_AUDIO_PHYSIQUE' = 'LIVRE_AUDIO_PHYSIQUE',
  'LIVRE_NUMERIQUE' = 'LIVRE_NUMERIQUE',
  'LIVRE_PAPIER' = 'LIVRE_PAPIER',
  'LOCATION_INSTRUMENT' = 'LOCATION_INSTRUMENT',
  'MATERIEL_ART_CREATIF' = 'MATERIEL_ART_CREATIF',
  'MUSEE_VENTE_DISTANCE' = 'MUSEE_VENTE_DISTANCE',
  'OEUVRE_ART' = 'OEUVRE_ART',
  'PARTITION' = 'PARTITION',
  'PLATEFORME_PRATIQUE_ARTISTIQUE' = 'PLATEFORME_PRATIQUE_ARTISTIQUE',
  'PRATIQUE_ART_VENTE_DISTANCE' = 'PRATIQUE_ART_VENTE_DISTANCE',
  'PODCAST' = 'PODCAST',
  'RENCONTRE_EN_LIGNE' = 'RENCONTRE_EN_LIGNE',
  'RENCONTRE_JEU' = 'RENCONTRE_JEU',
  'RENCONTRE' = 'RENCONTRE',
  'SALON' = 'SALON',
  'SEANCE_CINE' = 'SEANCE_CINE',
  'SEANCE_ESSAI_PRATIQUE_ART' = 'SEANCE_ESSAI_PRATIQUE_ART',
  'SPECTACLE_ENREGISTRE' = 'SPECTACLE_ENREGISTRE',
  'SPECTACLE_REPRESENTATION' = 'SPECTACLE_REPRESENTATION',
  'SPECTACLE_VENTE_DISTANCE' = 'SPECTACLE_VENTE_DISTANCE',
  'SUPPORT_PHYSIQUE_FILM' = 'SUPPORT_PHYSIQUE_FILM',
  'SUPPORT_PHYSIQUE_MUSIQUE_CD' = 'SUPPORT_PHYSIQUE_MUSIQUE_CD',
  'SUPPORT_PHYSIQUE_MUSIQUE_VINYLE' = 'SUPPORT_PHYSIQUE_MUSIQUE_VINYLE',
  'TELECHARGEMENT_LIVRE_AUDIO' = 'TELECHARGEMENT_LIVRE_AUDIO',
  'TELECHARGEMENT_MUSIQUE' = 'TELECHARGEMENT_MUSIQUE',
  'VISITE_GUIDEE' = 'VISITE_GUIDEE',
  'VISITE_VIRTUELLE' = 'VISITE_VIRTUELLE',
  'VISITE' = 'VISITE',
  'VOD' = 'VOD',
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SubcategoryIdEnumv2 {
  'ABO_BIBLIOTHEQUE' = 'ABO_BIBLIOTHEQUE',
  'ABO_CONCERT' = 'ABO_CONCERT',
  'ABO_JEU_VIDEO' = 'ABO_JEU_VIDEO',
  'ABO_LIVRE_NUMERIQUE' = 'ABO_LIVRE_NUMERIQUE',
  'ABO_LUDOTHEQUE' = 'ABO_LUDOTHEQUE',
  'ABO_MEDIATHEQUE' = 'ABO_MEDIATHEQUE',
  'ABO_PLATEFORME_MUSIQUE' = 'ABO_PLATEFORME_MUSIQUE',
  'ABO_PLATEFORME_VIDEO' = 'ABO_PLATEFORME_VIDEO',
  'ABO_PRATIQUE_ART' = 'ABO_PRATIQUE_ART',
  'ABO_PRESSE_EN_LIGNE' = 'ABO_PRESSE_EN_LIGNE',
  'ABO_SPECTACLE' = 'ABO_SPECTACLE',
  'ACHAT_INSTRUMENT' = 'ACHAT_INSTRUMENT',
  'ACTIVATION_EVENT' = 'ACTIVATION_EVENT',
  'ACTIVATION_THING' = 'ACTIVATION_THING',
  'APP_CULTURELLE' = 'APP_CULTURELLE',
  'ATELIER_PRATIQUE_ART' = 'ATELIER_PRATIQUE_ART',
  'AUTRE_SUPPORT_NUMERIQUE' = 'AUTRE_SUPPORT_NUMERIQUE',
  'BON_ACHAT_INSTRUMENT' = 'BON_ACHAT_INSTRUMENT',
  'CAPTATION_MUSIQUE' = 'CAPTATION_MUSIQUE',
  'CARTE_CINE_ILLIMITE' = 'CARTE_CINE_ILLIMITE',
  'CARTE_CINE_MULTISEANCES' = 'CARTE_CINE_MULTISEANCES',
  'CARTE_JEUNES' = 'CARTE_JEUNES',
  'CARTE_MUSEE' = 'CARTE_MUSEE',
  'CINE_PLEIN_AIR' = 'CINE_PLEIN_AIR',
  'CINE_VENTE_DISTANCE' = 'CINE_VENTE_DISTANCE',
  'CONCERT' = 'CONCERT',
  'CONCOURS' = 'CONCOURS',
  'CONFERENCE' = 'CONFERENCE',
  'DECOUVERTE_METIERS' = 'DECOUVERTE_METIERS',
  'ESCAPE_GAME' = 'ESCAPE_GAME',
  'EVENEMENT_CINE' = 'EVENEMENT_CINE',
  'EVENEMENT_JEU' = 'EVENEMENT_JEU',
  'EVENEMENT_MUSIQUE' = 'EVENEMENT_MUSIQUE',
  'EVENEMENT_PATRIMOINE' = 'EVENEMENT_PATRIMOINE',
  'FESTIVAL_ART_VISUEL' = 'FESTIVAL_ART_VISUEL',
  'FESTIVAL_CINE' = 'FESTIVAL_CINE',
  'FESTIVAL_LIVRE' = 'FESTIVAL_LIVRE',
  'FESTIVAL_MUSIQUE' = 'FESTIVAL_MUSIQUE',
  'FESTIVAL_SPECTACLE' = 'FESTIVAL_SPECTACLE',
  'JEU_EN_LIGNE' = 'JEU_EN_LIGNE',
  'JEU_SUPPORT_PHYSIQUE' = 'JEU_SUPPORT_PHYSIQUE',
  'LIVESTREAM_EVENEMENT' = 'LIVESTREAM_EVENEMENT',
  'LIVESTREAM_MUSIQUE' = 'LIVESTREAM_MUSIQUE',
  'LIVESTREAM_PRATIQUE_ARTISTIQUE' = 'LIVESTREAM_PRATIQUE_ARTISTIQUE',
  'LIVRE_AUDIO_PHYSIQUE' = 'LIVRE_AUDIO_PHYSIQUE',
  'LIVRE_NUMERIQUE' = 'LIVRE_NUMERIQUE',
  'LIVRE_PAPIER' = 'LIVRE_PAPIER',
  'LOCATION_INSTRUMENT' = 'LOCATION_INSTRUMENT',
  'MATERIEL_ART_CREATIF' = 'MATERIEL_ART_CREATIF',
  'MUSEE_VENTE_DISTANCE' = 'MUSEE_VENTE_DISTANCE',
  'OEUVRE_ART' = 'OEUVRE_ART',
  'PARTITION' = 'PARTITION',
  'PLATEFORME_PRATIQUE_ARTISTIQUE' = 'PLATEFORME_PRATIQUE_ARTISTIQUE',
  'PRATIQUE_ART_VENTE_DISTANCE' = 'PRATIQUE_ART_VENTE_DISTANCE',
  'PODCAST' = 'PODCAST',
  'RENCONTRE_EN_LIGNE' = 'RENCONTRE_EN_LIGNE',
  'RENCONTRE_JEU' = 'RENCONTRE_JEU',
  'RENCONTRE' = 'RENCONTRE',
  'SALON' = 'SALON',
  'SEANCE_CINE' = 'SEANCE_CINE',
  'SEANCE_ESSAI_PRATIQUE_ART' = 'SEANCE_ESSAI_PRATIQUE_ART',
  'SPECTACLE_ENREGISTRE' = 'SPECTACLE_ENREGISTRE',
  'SPECTACLE_REPRESENTATION' = 'SPECTACLE_REPRESENTATION',
  'SPECTACLE_VENTE_DISTANCE' = 'SPECTACLE_VENTE_DISTANCE',
  'SUPPORT_PHYSIQUE_FILM' = 'SUPPORT_PHYSIQUE_FILM',
  'SUPPORT_PHYSIQUE_MUSIQUE_CD' = 'SUPPORT_PHYSIQUE_MUSIQUE_CD',
  'SUPPORT_PHYSIQUE_MUSIQUE_VINYLE' = 'SUPPORT_PHYSIQUE_MUSIQUE_VINYLE',
  'TELECHARGEMENT_LIVRE_AUDIO' = 'TELECHARGEMENT_LIVRE_AUDIO',
  'TELECHARGEMENT_MUSIQUE' = 'TELECHARGEMENT_MUSIQUE',
  'VISITE_GUIDEE' = 'VISITE_GUIDEE',
  'VISITE_VIRTUELLE' = 'VISITE_VIRTUELLE',
  'VISITE' = 'VISITE',
  'VOD' = 'VOD',
}
/**
 * @export
 * @interface SubcategoryResponseModelv2
 */
export interface SubcategoryResponseModelv2 {
  /**
   * @type {string}
   * @memberof SubcategoryResponseModelv2
   */
  appLabel: string
  /**
   * @type {CategoryIdEnum}
   * @memberof SubcategoryResponseModelv2
   */
  categoryId: CategoryIdEnum
  /**
   * @type {HomepageLabelNameEnumv2}
   * @memberof SubcategoryResponseModelv2
   */
  homepageLabelName: HomepageLabelNameEnumv2
  /**
   * @type {SubcategoryIdEnumv2}
   * @memberof SubcategoryResponseModelv2
   */
  id: SubcategoryIdEnumv2
  /**
   * @type {boolean}
   * @memberof SubcategoryResponseModelv2
   */
  isEvent: boolean
  /**
   * @type {NativeCategoryIdEnumv2}
   * @memberof SubcategoryResponseModelv2
   */
  nativeCategoryId: NativeCategoryIdEnumv2
  /**
   * @type {OnlineOfflinePlatformChoicesEnum}
   * @memberof SubcategoryResponseModelv2
   */
  onlineOfflinePlatform: OnlineOfflinePlatformChoicesEnum
  /**
   * @type {SearchGroupNameEnumv2}
   * @memberof SubcategoryResponseModelv2
   */
  searchGroupName: SearchGroupNameEnumv2
}
/**
 * @export
 * @interface SubscriptionMessage
 */
export interface SubscriptionMessage {
  /**
   * @type {CallToActionMessage}
   * @memberof SubscriptionMessage
   */
  callToAction?: CallToActionMessage | null
  /**
   * @type {PopOverIcon}
   * @memberof SubscriptionMessage
   */
  popOverIcon?: PopOverIcon | null
  /**
   * @type {string}
   * @memberof SubscriptionMessage
   */
  updatedAt?: string | null
  /**
   * @type {string}
   * @memberof SubscriptionMessage
   */
  userMessage: string
}
/**
 * @export
 * @interface SubscriptionMessageV2
 */
export interface SubscriptionMessageV2 {
  /**
   * @type {CallToActionMessage}
   * @memberof SubscriptionMessageV2
   */
  callToAction?: CallToActionMessage | null
  /**
   * @type {string}
   * @memberof SubscriptionMessageV2
   */
  messageSummary?: string | null
  /**
   * @type {PopOverIcon}
   * @memberof SubscriptionMessageV2
   */
  popOverIcon?: PopOverIcon | null
  /**
   * @type {string}
   * @memberof SubscriptionMessageV2
   */
  updatedAt?: string | null
  /**
   * @type {string}
   * @memberof SubscriptionMessageV2
   */
  userMessage: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SubscriptionStatus {
  'has_to_complete_subscription' = 'has_to_complete_subscription',
  'has_subscription_pending' = 'has_subscription_pending',
  'has_subscription_issues' = 'has_subscription_issues',
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SubscriptionStep {
  'email-validation' = 'email-validation',
  'maintenance' = 'maintenance',
  'phone-validation' = 'phone-validation',
  'profile-completion' = 'profile-completion',
  'identity-check' = 'identity-check',
  'honor-statement' = 'honor-statement',
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SubscriptionStepCompletionState {
  'completed' = 'completed',
  'current' = 'current',
  'disabled' = 'disabled',
  'retry' = 'retry',
}
/**
 * @export
 * @interface SubscriptionStepDetailsResponse
 */
export interface SubscriptionStepDetailsResponse {
  /**
   * @type {SubscriptionStepCompletionState}
   * @memberof SubscriptionStepDetailsResponse
   */
  completionState: SubscriptionStepCompletionState
  /**
   * @type {SubscriptionStep}
   * @memberof SubscriptionStepDetailsResponse
   */
  name: SubscriptionStep
  /**
   * @type {string}
   * @memberof SubscriptionStepDetailsResponse
   */
  subtitle?: string | null
  /**
   * @type {SubscriptionStepTitle}
   * @memberof SubscriptionStepDetailsResponse
   */
  title: SubscriptionStepTitle
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum SubscriptionStepTitle {
  'Numéro de téléphone' = 'Numéro de téléphone',
  'Profil' = 'Profil',
  'Identification' = 'Identification',
  'Confirmation' = 'Confirmation',
}
/**
 * @export
 * @interface SubscriptionStepperResponse
 */
export interface SubscriptionStepperResponse {
  /**
   * @type {Array<IdentityCheckMethod>}
   * @memberof SubscriptionStepperResponse
   */
  allowedIdentityCheckMethods: Array<IdentityCheckMethod>
  /**
   * @type {string}
   * @memberof SubscriptionStepperResponse
   */
  errorMessage?: string | null
  /**
   * @type {MaintenancePageType}
   * @memberof SubscriptionStepperResponse
   */
  maintenancePageType?: MaintenancePageType | null
  /**
   * @type {Array<SubscriptionStepDetailsResponse>}
   * @memberof SubscriptionStepperResponse
   */
  subscriptionStepsToDisplay: Array<SubscriptionStepDetailsResponse>
  /**
   * @type {string}
   * @memberof SubscriptionStepperResponse
   */
  subtitle?: string | null
  /**
   * @type {string}
   * @memberof SubscriptionStepperResponse
   */
  title: string
}
/**
 * @export
 * @interface SubscriptionStepperResponseV2
 */
export interface SubscriptionStepperResponseV2 {
  /**
   * @type {Array<IdentityCheckMethod>}
   * @memberof SubscriptionStepperResponseV2
   */
  allowedIdentityCheckMethods: Array<IdentityCheckMethod>
  /**
   * @type {boolean}
   * @memberof SubscriptionStepperResponseV2
   */
  hasIdentityCheckPending: boolean
  /**
   * @type {MaintenancePageType}
   * @memberof SubscriptionStepperResponseV2
   */
  maintenancePageType?: MaintenancePageType | null
  /**
   * @type {SubscriptionStep}
   * @memberof SubscriptionStepperResponseV2
   */
  nextSubscriptionStep?: SubscriptionStep | null
  /**
   * @type {SubscriptionMessageV2}
   * @memberof SubscriptionStepperResponseV2
   */
  subscriptionMessage?: SubscriptionMessageV2 | null
  /**
   * @type {Array<SubscriptionStepDetailsResponse>}
   * @memberof SubscriptionStepperResponseV2
   */
  subscriptionStepsToDisplay: Array<SubscriptionStepDetailsResponse>
  /**
   * @type {string}
   * @memberof SubscriptionStepperResponseV2
   */
  subtitle?: string | null
  /**
   * @type {string}
   * @memberof SubscriptionStepperResponseV2
   */
  title: string
}
/**
 * @export
 * @interface SuspendAccountForSuspiciousLoginRequest
 */
export interface SuspendAccountForSuspiciousLoginRequest {
  /**
   * @type {string}
   * @memberof SuspendAccountForSuspiciousLoginRequest
   */
  token: string
}
/**
 * @export
 * @interface TrustedDevice
 */
export interface TrustedDevice {
  /**
   * @type {string}
   * @memberof TrustedDevice
   */
  deviceId: string
  /**
   * @type {string}
   * @memberof TrustedDevice
   */
  os?: string | null
  /**
   * @type {string}
   * @memberof TrustedDevice
   */
  source?: string | null
}
/**
 * @export
 * @interface UpdateEmailTokenExpiration
 */
export interface UpdateEmailTokenExpiration {
  /**
   * @type {string}
   * @memberof UpdateEmailTokenExpiration
   */
  expiration?: string | null
}
/**
 * @export
 * @interface UserProfileEmailUpdate
 */
export interface UserProfileEmailUpdate {
  /**
   * @type {string}
   * @memberof UserProfileEmailUpdate
   */
  email: string
  /**
   * @type {string}
   * @memberof UserProfileEmailUpdate
   */
  password: string
}
/**
 * @export
 * @interface UserProfilePatchRequest
 */
export interface UserProfilePatchRequest {
  /**
   * @type {ActivityIdEnum}
   * @memberof UserProfilePatchRequest
   */
  activityId?: ActivityIdEnum | null
  /**
   * @type {string}
   * @memberof UserProfilePatchRequest
   */
  city?: string | null
  /**
   * @type {string}
   * @memberof UserProfilePatchRequest
   */
  origin?: string | null
  /**
   * @type {string}
   * @memberof UserProfilePatchRequest
   */
  phoneNumber?: string | null
  /**
   * @type {string}
   * @memberof UserProfilePatchRequest
   */
  postalCode?: string | null
  /**
   * @type {NotificationSubscriptions}
   * @memberof UserProfilePatchRequest
   */
  subscriptions?: NotificationSubscriptions | null
}
/**
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
  /**
   * @type {Array<AchievementResponse>}
   * @memberof UserProfileResponse
   */
  achievements: Array<AchievementResponse>
  /**
   * @type {ActivityIdEnum}
   * @memberof UserProfileResponse
   */
  activityId?: ActivityIdEnum | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  birthDate?: string | null
  /**
   * @type {{ [key: string]: number; }}
   * @memberof UserProfileResponse
   */
  bookedOffers: { [key: string]: number }
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  city?: string | null
  /**
   * @type {CurrencyEnum}
   * @memberof UserProfileResponse
   */
  currency: CurrencyEnum
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  depositActivationDate?: string | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  depositExpirationDate?: string | null
  /**
   * @type {DepositType}
   * @memberof UserProfileResponse
   */
  depositType?: DepositType | null
  /**
   * @type {DomainsCredit}
   * @memberof UserProfileResponse
   */
  domainsCredit?: DomainsCredit | null
  /**
   * @type {EligibilityType}
   * @memberof UserProfileResponse
   */
  eligibility?: EligibilityType | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  eligibilityEndDatetime?: string | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  eligibilityStartDatetime?: string | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  email: string
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  firstDepositActivationDate?: string | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  firstName?: string | null
  /**
   * @type {boolean}
   * @memberof UserProfileResponse
   */
  hasPassword: boolean
  /**
   * @type {number}
   * @memberof UserProfileResponse
   */
  id: number
  /**
   * @type {boolean}
   * @memberof UserProfileResponse
   */
  isBeneficiary: boolean
  /**
   * @type {boolean}
   * @memberof UserProfileResponse
   */
  isEligibleForBeneficiaryUpgrade: boolean
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  lastName?: string | null
  /**
   * @type {boolean}
   * @memberof UserProfileResponse
   */
  needsToFillCulturalSurvey: boolean
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  phoneNumber?: string | null
  /**
   * @type {string}
   * @memberof UserProfileResponse
   */
  postalCode?: string | null
  /**
   * @type {number}
   * @memberof UserProfileResponse
   */
  recreditAmountToShow?: number | null
  /**
   * @type {boolean}
   * @memberof UserProfileResponse
   */
  requiresIdCheck: boolean
  /**
   * @type {Array<UserRole>}
   * @memberof UserProfileResponse
   */
  roles: Array<UserRole>
  /**
   * @type {boolean}
   * @memberof UserProfileResponse
   */
  showEligibleCard: boolean
  /**
   * @type {YoungStatusResponse}
   * @memberof UserProfileResponse
   */
  status: YoungStatusResponse
  /**
   * @type {SubscriptionMessage}
   * @memberof UserProfileResponse
   */
  subscriptionMessage?: SubscriptionMessage | null
  /**
   * @type {NotificationSubscriptions}
   * @memberof UserProfileResponse
   */
  subscriptions: NotificationSubscriptions
}
/**
 * @export
 * @interface UserReportedOffersResponse
 */
export interface UserReportedOffersResponse {
  /**
   * @type {Array<ReportedOffer>}
   * @memberof UserReportedOffersResponse
   */
  reportedOffers: Array<ReportedOffer>
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UserRole {
  'ADMIN' = 'ADMIN',
  'ANONYMIZED' = 'ANONYMIZED',
  'BENEFICIARY' = 'BENEFICIARY',
  'PRO' = 'PRO',
  'NON_ATTACHED_PRO' = 'NON_ATTACHED_PRO',
  'UNDERAGE_BENEFICIARY' = 'UNDERAGE_BENEFICIARY',
  'TEST' = 'TEST',
}
/**
 * @export
 * @interface UserSuspensionDateResponse
 */
export interface UserSuspensionDateResponse {
  /**
   * @type {string}
   * @memberof UserSuspensionDateResponse
   */
  date?: string | null
}
/**
 * @export
 * @interface UserSuspensionStatusResponse
 */
export interface UserSuspensionStatusResponse {
  /**
   * @type {AccountState}
   * @memberof UserSuspensionStatusResponse
   */
  status: AccountState
}
/**
 * @export
 * @interface ValidateEmailRequest
 */
export interface ValidateEmailRequest {
  /**
   * @type {TrustedDevice}
   * @memberof ValidateEmailRequest
   */
  deviceInfo?: TrustedDevice | null
  /**
   * @type {string}
   * @memberof ValidateEmailRequest
   */
  emailValidationToken: string
}
/**
 * @export
 * @interface ValidateEmailResponse
 */
export interface ValidateEmailResponse {
  /**
   * @type {string}
   * @memberof ValidateEmailResponse
   */
  accessToken: string
  /**
   * @type {string}
   * @memberof ValidateEmailResponse
   */
  refreshToken: string
}
/**
 * @export
 * @interface ValidatePhoneNumberRequest
 */
export interface ValidatePhoneNumberRequest {
  /**
   * @type {string}
   * @memberof ValidatePhoneNumberRequest
   */
  code: string
}
/**
 * @export
 * @interface ValidationError
 */
export interface ValidationError extends Array<ValidationErrorElement> {}
/**
 * @export
 * @interface ValidationErrorElement
 */
export interface ValidationErrorElement {
  /**
   * @type {any}
   * @memberof ValidationErrorElement
   */
  ctx?: any
  /**
   * @type {Array<string>}
   * @memberof ValidationErrorElement
   */
  loc: Array<string>
  /**
   * @type {string}
   * @memberof ValidationErrorElement
   */
  msg: string
  /**
   * @type {string}
   * @memberof ValidationErrorElement
   */
  type: string
}
/**
 * @export
 * @interface VenueAccessibilityModel
 */
export interface VenueAccessibilityModel {
  /**
   * @type {boolean}
   * @memberof VenueAccessibilityModel
   */
  audioDisability?: boolean | null
  /**
   * @type {boolean}
   * @memberof VenueAccessibilityModel
   */
  mentalDisability?: boolean | null
  /**
   * @type {boolean}
   * @memberof VenueAccessibilityModel
   */
  motorDisability?: boolean | null
  /**
   * @type {boolean}
   * @memberof VenueAccessibilityModel
   */
  visualDisability?: boolean | null
}
/**
 * @export
 * @interface VenueContactModel
 */
export interface VenueContactModel {
  /**
   * @type {string}
   * @memberof VenueContactModel
   */
  email?: string | null
  /**
   * @type {string}
   * @memberof VenueContactModel
   */
  phoneNumber?: string | null
  /**
   * @type {{ [key: string]: string; }}
   * @memberof VenueContactModel
   */
  socialMedias?: { [key: string]: string } | null
  /**
   * @type {string}
   * @memberof VenueContactModel
   */
  website?: string | null
}
/**
 * @export
 * @interface VenueResponse
 */
export interface VenueResponse {
  /**
   * @type {VenueAccessibilityModel}
   * @memberof VenueResponse
   */
  accessibility: VenueAccessibilityModel
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  address?: string | null
  /**
   * @type {BannerMetaModel}
   * @memberof VenueResponse
   */
  bannerMeta?: BannerMetaModel | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  bannerUrl?: string | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  city?: string | null
  /**
   * @type {VenueContactModel}
   * @memberof VenueResponse
   */
  contact?: VenueContactModel | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  description?: string | null
  /**
   * @type {ExternalAccessibilityDataModel}
   * @memberof VenueResponse
   */
  externalAccessibilityData?: ExternalAccessibilityDataModel | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  externalAccessibilityId?: string | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  externalAccessibilityUrl?: string | null
  /**
   * @type {number}
   * @memberof VenueResponse
   */
  id: number
  /**
   * @type {boolean}
   * @memberof VenueResponse
   */
  isOpenToPublic?: boolean | null
  /**
   * @type {boolean}
   * @memberof VenueResponse
   */
  isPermanent?: boolean | null
  /**
   * @type {boolean}
   * @memberof VenueResponse
   */
  isVirtual: boolean
  /**
   * @type {number}
   * @memberof VenueResponse
   */
  latitude?: number | null
  /**
   * @type {number}
   * @memberof VenueResponse
   */
  longitude?: number | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  name: string
  /**
   * @type {any}
   * @memberof VenueResponse
   */
  openingHours?: any | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  postalCode?: string | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  publicName?: string | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  street?: string | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  timezone: string
  /**
   * @type {VenueTypeCodeKey}
   * @memberof VenueResponse
   */
  venueTypeCode?: VenueTypeCodeKey | null
  /**
   * @type {string}
   * @memberof VenueResponse
   */
  withdrawalDetails?: string | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum VenueTypeCodeKey {
  'ADMINISTRATIVE' = 'ADMINISTRATIVE',
  'ARTISTIC_COURSE' = 'ARTISTIC_COURSE',
  'BOOKSTORE' = 'BOOKSTORE',
  'CONCERT_HALL' = 'CONCERT_HALL',
  'CREATIVE_ARTS_STORE' = 'CREATIVE_ARTS_STORE',
  'CULTURAL_CENTRE' = 'CULTURAL_CENTRE',
  'DIGITAL' = 'DIGITAL',
  'DISTRIBUTION_STORE' = 'DISTRIBUTION_STORE',
  'FESTIVAL' = 'FESTIVAL',
  'GAMES' = 'GAMES',
  'LIBRARY' = 'LIBRARY',
  'MOVIE' = 'MOVIE',
  'MUSEUM' = 'MUSEUM',
  'MUSICAL_INSTRUMENT_STORE' = 'MUSICAL_INSTRUMENT_STORE',
  'OTHER' = 'OTHER',
  'PATRIMONY_TOURISM' = 'PATRIMONY_TOURISM',
  'PERFORMING_ARTS' = 'PERFORMING_ARTS',
  'RECORD_STORE' = 'RECORD_STORE',
  'SCIENTIFIC_CULTURE' = 'SCIENTIFIC_CULTURE',
  'TRAVELING_CINEMA' = 'TRAVELING_CINEMA',
  'VISUAL_ARTS' = 'VISUAL_ARTS',
}
/**
 * @export
 * @interface VisualDisabilityModel
 */
export interface VisualDisabilityModel {
  /**
   * @type {Array<string>}
   * @memberof VisualDisabilityModel
   */
  audioDescription?: Array<string>
  /**
   * @type {string}
   * @memberof VisualDisabilityModel
   */
  soundBeacon?: string
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum WithdrawalTypeEnum {
  'by_email' = 'by_email',
  'in_app' = 'in_app',
  'no_ticket' = 'no_ticket',
  'on_site' = 'on_site',
}
/**
 * @export
 * @interface YoungStatusResponse
 */
export interface YoungStatusResponse {
  /**
   * @type {YoungStatusType}
   * @memberof YoungStatusResponse
   */
  statusType: YoungStatusType
  /**
   * @type {SubscriptionStatus}
   * @memberof YoungStatusResponse
   */
  subscriptionStatus?: SubscriptionStatus | null
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum YoungStatusType {
  'eligible' = 'eligible',
  'non_eligible' = 'non_eligible',
  'beneficiary' = 'beneficiary',
  'ex_beneficiary' = 'ex_beneficiary',
  'suspended' = 'suspended',
}

/**
 * DefaultApi - fetch parameter creator
 */
export const DefaultApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * @summary delete_favorite <DELETE>
     * @param {number} favorite_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNativeV1MeFavoritesfavoriteId(
      favorite_id: number,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'favorite_id' is not null or undefined
      if (favorite_id === null || favorite_id === undefined) {
        throw new RequiredError(
          'favorite_id',
          'Required parameter favorite_id was null or undefined when calling deleteNativeV1MeFavoritesfavoriteId.'
        )
      }
      let pathname = `/native/v1/me/favorites/{favorite_id}`.replace(
        `{${'favorite_id'}}`,
        encodeURIComponent(String(favorite_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'DELETE' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary account_suspension_token_validation <GET>
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1AccountSuspendTokenValidationtoken(
      token: string,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          'token',
          'Required parameter token was null or undefined when calling getNativeV1AccountSuspendTokenValidationtoken.'
        )
      }
      let pathname = `/native/v1/account/suspend/token_validation/{token}`.replace(
        `{${'token'}}`,
        encodeURIComponent(String(token))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_account_suspension_date <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1AccountSuspensionDate(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account/suspension_date`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_account_suspension_status <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1AccountSuspensionStatus(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account/suspension_status`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_artist <GET>
     * @param {string} artist_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ArtistsartistId(artist_id: string, options: any = {}): Promise<FetchArgs> {
      // verify required parameter 'artist_id' is not null or undefined
      if (artist_id === null || artist_id === undefined) {
        throw new RequiredError(
          'artist_id',
          'Required parameter artist_id was null or undefined when calling getNativeV1ArtistsartistId.'
        )
      }
      let pathname = `/native/v1/artists/{artist_id}`.replace(
        `{${'artist_id'}}`,
        encodeURIComponent(String(artist_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_banner <GET>
     * @param {boolean} [isGeolocated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Banner(isGeolocated?: boolean, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/banner`
      const queryParameters: any = {}

      if (isGeolocated != null) {
        queryParameters['isGeolocated'] = isGeolocated
      }

      const encodedQueryParams =
        '?' +
        Object.keys(queryParameters)
          .map((key) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(queryParameters[key])}`
          })
          .join('&')
      pathname += encodedQueryParams
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_bookings <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Bookings(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/bookings`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_categories <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Categories(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/categories`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_cultural_survey_questions <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1CulturalSurveyQuestions(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/cultural_survey/questions`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary email_validation_remaining_resends <GET>
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1EmailValidationRemainingResendsemail(
      email: string,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'email' is not null or undefined
      if (email === null || email === undefined) {
        throw new RequiredError(
          'email',
          'Required parameter email was null or undefined when calling getNativeV1EmailValidationRemainingResendsemail.'
        )
      }
      let pathname = `/native/v1/email_validation_remaining_resends/{email}`.replace(
        `{${'email'}}`,
        encodeURIComponent(String(email))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_user_profile <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Me(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/me`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_favorites <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1MeFavorites(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/me/favorites`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_favorites_count <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1MeFavoritesCount(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/me/favorites/count`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary google_oauth_state <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OauthState(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/oauth/state`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary report_offer_reasons <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OfferReportReasons(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/offer/report/reasons`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_offerer_headline_offer <GET>
     * @param {number} offerer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OffereroffererIdHeadlineOffer(
      offerer_id: number,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'offerer_id' is not null or undefined
      if (offerer_id === null || offerer_id === undefined) {
        throw new RequiredError(
          'offerer_id',
          'Required parameter offerer_id was null or undefined when calling getNativeV1OffereroffererIdHeadlineOffer.'
        )
      }
      let pathname = `/native/v1/offerer/{offerer_id}/headline-offer`.replace(
        `{${'offerer_id'}}`,
        encodeURIComponent(String(offerer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_offer <GET>
     * @deprecated
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OfferofferId(offer_id: number, options: any = {}): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling getNativeV1OfferofferId.'
        )
      }
      let pathname = `/native/v1/offer/{offer_id}`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary offer_chronicles <GET>
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OfferofferIdChronicles(
      offer_id: number,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling getNativeV1OfferofferIdChronicles.'
        )
      }
      let pathname = `/native/v1/offer/{offer_id}/chronicles`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary user_reported_offers <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OffersReports(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/offers/reports`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary phone_validation_remaining_attempts <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1PhoneValidationRemainingAttempts(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/phone_validation/remaining_attempts`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_email_update_status <GET>
     * @deprecated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ProfileEmailUpdateStatus(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/profile/email_update/status`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_email_update_token_expiration_date <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ProfileTokenExpiration(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/profile/token_expiration`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_available_reactions <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ReactionAvailable(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/reaction/available`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary similar_offers <GET>
     * @param {number} offer_id
     * @param {number} [longitude]
     * @param {number} [latitude]
     * @param {Array<string>} [categories]
     * @param {Array<string>} [subcategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1RecommendationSimilarOffersofferId(
      offer_id: number,
      longitude?: number,
      latitude?: number,
      categories?: Array<string>,
      subcategories?: Array<string>,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling getNativeV1RecommendationSimilarOffersofferId.'
        )
      }
      let pathname = `/native/v1/recommendation/similar_offers/{offer_id}`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      const queryParameters: any = {}

      if (longitude != null) {
        queryParameters['longitude'] = longitude
      }

      if (latitude != null) {
        queryParameters['latitude'] = latitude
      }

      if (categories != null) {
        queryParameters['categories'] = categories
      }

      if (subcategories != null) {
        queryParameters['subcategories'] = subcategories
      }

      const encodedQueryParams =
        '?' +
        Object.keys(queryParameters)
          .map((key) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(queryParameters[key])}`
          })
          .join('&')
      pathname += encodedQueryParams
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_settings <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Settings(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/settings`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_subcategories_v2 <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubcategoriesV2(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/subcategories/v2`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_activity_types <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionActivityTypes(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/subscription/activity_types`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary next_subscription_step <GET>
     * @deprecated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionNextStep(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/subscription/next_step`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_profile <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionProfile(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/subscription/profile`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_subscription_stepper_deprecated <GET>
     * @deprecated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionStepper(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/subscription/stepper`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_venue <GET>
     * @param {number} venue_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1VenuevenueId(venue_id: number, options: any = {}): Promise<FetchArgs> {
      // verify required parameter 'venue_id' is not null or undefined
      if (venue_id === null || venue_id === undefined) {
        throw new RequiredError(
          'venue_id',
          'Required parameter venue_id was null or undefined when calling getNativeV1VenuevenueId.'
        )
      }
      let pathname = `/native/v1/venue/{venue_id}`.replace(
        `{${'venue_id'}}`,
        encodeURIComponent(String(venue_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_offer_v2 <GET>
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV2OfferofferId(offer_id: number, options: any = {}): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling getNativeV2OfferofferId.'
        )
      }
      let pathname = `/native/v2/offer/{offer_id}`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_email_update_status <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV2ProfileEmailUpdateStatus(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v2/profile/email_update/status`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_subscription_stepper <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV2SubscriptionStepper(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v2/subscription/stepper`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'GET' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary patch_user_profile <PATCH>
     * @param {UserProfilePatchRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchNativeV1Profile(
      body?: UserProfilePatchRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/profile`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'PATCH' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'UserProfilePatchRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary create_account <POST>
     * @param {AccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Account(body?: AccountRequest, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'AccountRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary anonymize_account <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountAnonymize(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account/anonymize`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary suspend_account <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountSuspend(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account/suspend`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary suspend_account_for_hack_suspicion <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountSuspendForHackSuspicion(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account/suspend_for_hack_suspicion`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary suspend_account_for_suspicious_login <POST>
     * @param {SuspendAccountForSuspiciousLoginRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountSuspendForSuspiciousLogin(
      body?: SuspendAccountForSuspiciousLoginRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/account/suspend_for_suspicious_login`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'SuspendAccountForSuspiciousLoginRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary unsuspend_account <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountUnsuspend(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/account/unsuspend`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary mark_achievements_as_seen <POST>
     * @param {MarkAchievementsAsSeenRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AchievementsMarkAsSeen(
      body?: MarkAchievementsAsSeenRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/achievements/mark_as_seen`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'MarkAchievementsAsSeenRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary book_offer <POST>
     * @param {BookOfferRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Bookings(body?: BookOfferRequest, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/bookings`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'BookOfferRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary cancel_booking <POST>
     * @param {number} booking_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1BookingsbookingIdCancel(
      booking_id: number,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'booking_id' is not null or undefined
      if (booking_id === null || booking_id === undefined) {
        throw new RequiredError(
          'booking_id',
          'Required parameter booking_id was null or undefined when calling postNativeV1BookingsbookingIdCancel.'
        )
      }
      let pathname = `/native/v1/bookings/{booking_id}/cancel`.replace(
        `{${'booking_id'}}`,
        encodeURIComponent(String(booking_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary flag_booking_as_used <POST>
     * @param {number} booking_id
     * @param {BookingDisplayStatusRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1BookingsbookingIdToggleDisplay(
      booking_id: number,
      body?: BookingDisplayStatusRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'booking_id' is not null or undefined
      if (booking_id === null || booking_id === undefined) {
        throw new RequiredError(
          'booking_id',
          'Required parameter booking_id was null or undefined when calling postNativeV1BookingsbookingIdToggleDisplay.'
        )
      }
      let pathname = `/native/v1/bookings/{booking_id}/toggle_display`.replace(
        `{${'booking_id'}}`,
        encodeURIComponent(String(booking_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'BookingDisplayStatusRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary change_password <POST>
     * @param {ChangePasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ChangePassword(
      body?: ChangePasswordRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/change_password`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ChangePasswordRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary cookies_consent <POST>
     * @param {CookieConsentRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1CookiesConsent(
      body?: CookieConsentRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/cookies_consent`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'CookieConsentRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary post_cultural_survey_answers <POST>
     * @param {CulturalSurveyAnswersRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1CulturalSurveyAnswers(
      body?: CulturalSurveyAnswersRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/cultural_survey/answers`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'CulturalSurveyAnswersRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary post_feedback <POST>
     * @param {PostFeedbackBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Feedback(body?: PostFeedbackBody, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/feedback`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'PostFeedbackBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary create_favorite <POST>
     * @param {FavoriteRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1MeFavorites(body?: FavoriteRequest, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/me/favorites`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'FavoriteRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary create_account_with_google_sso <POST>
     * @param {GoogleAccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OauthGoogleAccount(
      body?: GoogleAccountRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/oauth/google/account`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'GoogleAccountRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary google_auth <POST>
     * @param {GoogleSigninRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OauthGoogleAuthorize(
      body?: GoogleSigninRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/oauth/google/authorize`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'GoogleSigninRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary report_offer <POST>
     * @param {number} offer_id
     * @param {OfferReportRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OfferofferIdReport(
      offer_id: number,
      body?: OfferReportRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling postNativeV1OfferofferIdReport.'
        )
      }
      let pathname = `/native/v1/offer/{offer_id}/report`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'OfferReportRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_offers_showtimes <POST>
     * @deprecated
     * @param {OffersStocksRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OffersStocks(
      body?: OffersStocksRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/offers/stocks`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'OffersStocksRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary patch_user_profile <POST>
     * @param {UserProfilePatchRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Profile(
      body?: UserProfilePatchRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/profile`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'UserProfilePatchRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary cancel_email_update <POST>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ProfileEmailUpdateCancel(
      body?: ChangeBeneficiaryEmailBody,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/profile/email_update/cancel`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ChangeBeneficiaryEmailBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary confirm_email_update <POST>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ProfileEmailUpdateConfirm(
      body?: ChangeBeneficiaryEmailBody,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/profile/email_update/confirm`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ChangeBeneficiaryEmailBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary update_user_email <POST>
     * @deprecated
     * @param {UserProfileEmailUpdate} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ProfileUpdateEmail(
      body?: UserProfileEmailUpdate,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/profile/update_email`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'UserProfileEmailUpdate' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary post_reaction <POST>
     * @param {PostReactionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Reaction(body?: PostReactionRequest, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/reaction`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'PostReactionRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary playlist <POST>
     * @param {PlaylistRequestBody} [body]
     * @param {string} [modelEndpoint]
     * @param {number} [longitude]
     * @param {number} [latitude]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1RecommendationPlaylist(
      body?: PlaylistRequestBody,
      modelEndpoint?: string,
      longitude?: number,
      latitude?: number,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/recommendation/playlist`
      const queryParameters: any = {}

      if (modelEndpoint != null) {
        queryParameters['modelEndpoint'] = modelEndpoint
      }

      if (longitude != null) {
        queryParameters['longitude'] = longitude
      }

      if (latitude != null) {
        queryParameters['latitude'] = latitude
      }

      const encodedQueryParams =
        '?' +
        Object.keys(queryParameters)
          .map((key) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(queryParameters[key])}`
          })
          .join('&')
      pathname += encodedQueryParams
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'PlaylistRequestBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary refresh <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1RefreshAccessToken(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/refresh_access_token`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary request_password_reset <POST>
     * @param {RequestPasswordResetRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1RequestPasswordReset(
      body?: RequestPasswordResetRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/request_password_reset`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'RequestPasswordResetRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary resend_email_validation <POST>
     * @param {ResendEmailValidationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ResendEmailValidation(
      body?: ResendEmailValidationRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/resend_email_validation`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ResendEmailValidationRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary reset_password <POST>
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ResetPassword(
      body?: ResetPasswordRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/reset_password`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ResetPasswordRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary reset_recredit_amount_to_show <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ResetRecreditAmountToShow(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/reset_recredit_amount_to_show`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary send_offer_link_by_push <POST>
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SendOfferLinkByPushofferId(
      offer_id: number,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling postNativeV1SendOfferLinkByPushofferId.'
        )
      }
      let pathname = `/native/v1/send_offer_link_by_push/{offer_id}`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary On iOS native app, users cannot book numeric offers with price > 0, so give them webapp link.
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SendOfferWebappLinkByEmailofferId(
      offer_id: number,
      options: any = {}
    ): Promise<FetchArgs> {
      // verify required parameter 'offer_id' is not null or undefined
      if (offer_id === null || offer_id === undefined) {
        throw new RequiredError(
          'offer_id',
          'Required parameter offer_id was null or undefined when calling postNativeV1SendOfferWebappLinkByEmailofferId.'
        )
      }
      let pathname = `/native/v1/send_offer_webapp_link_by_email/{offer_id}`.replace(
        `{${'offer_id'}}`,
        encodeURIComponent(String(offer_id))
      )
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary send_phone_validation_code <POST>
     * @param {SendPhoneValidationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SendPhoneValidationCode(
      body?: SendPhoneValidationRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/send_phone_validation_code`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'SendPhoneValidationRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary signin <POST>
     * @param {SigninRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Signin(body?: SigninRequest, options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/signin`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'SigninRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary create_honor_statement_fraud_check <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SubscriptionHonorStatement(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v1/subscription/honor_statement`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary complete_profile <POST>
     * @param {ProfileUpdateRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SubscriptionProfile(
      body?: ProfileUpdateRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/subscription/profile`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ProfileUpdateRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary start_identification_session <POST>
     * @param {IdentificationSessionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1UbbleIdentification(
      body?: IdentificationSessionRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/ubble_identification`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'IdentificationSessionRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary validate_email <POST>
     * @param {ValidateEmailRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ValidateEmail(
      body?: ValidateEmailRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/validate_email`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ValidateEmailRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary validate_phone_number <POST>
     * @param {ValidatePhoneNumberRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ValidatePhoneNumber(
      body?: ValidatePhoneNumberRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/validate_phone_number`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ValidatePhoneNumberRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary get_offers_and_stocks <POST>
     * @param {OffersStocksRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2OffersStocks(
      body?: OffersStocksRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v2/offers/stocks`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'OffersStocksRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary confirm_email_update <POST>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileEmailUpdateConfirm(
      body?: ChangeBeneficiaryEmailBody,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v2/profile/email_update/confirm`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ChangeBeneficiaryEmailBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary select_new_email <POST>
     * @param {NewEmailSelectionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileEmailUpdateNewEmail(
      body?: NewEmailSelectionRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v2/profile/email_update/new_email`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'NewEmailSelectionRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary select_new_password <POST>
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileEmailUpdateNewPassword(
      body?: ResetPasswordRequest,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v2/profile/email_update/new_password`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ResetPasswordRequest' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary update_user_email <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileUpdateEmail(options: any = {}): Promise<FetchArgs> {
      let pathname = `/native/v2/profile/update_email`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      // authentication JWTAuth required
      secureOptions = Object.assign(secureOptions, { credentials: 'include' })
      const localVarRequestOptions = Object.assign({ method: 'POST' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
    /**
     * @summary validate_user_email <PUT>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putNativeV1ProfileEmailUpdateValidate(
      body?: ChangeBeneficiaryEmailBody,
      options: any = {}
    ): Promise<FetchArgs> {
      let pathname = `/native/v1/profile/email_update/validate`
      let secureOptions = Object.assign(options, { credentials: 'omit' })
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, secureOptions)
      const localVarHeaderParameter = await getAuthenticationHeaders(secureOptions)
      localVarHeaderParameter['Content-Type'] = 'application/json'
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers)
      const needsSerialization =
        <any>'ChangeBeneficiaryEmailBody' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || ''
      return {
        url: pathname,
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 */
export const DefaultApiFp = function (api: DefaultApi, configuration?: Configuration) {
  return {
    /**
     *
     * @summary delete_favorite <DELETE>
     * @param {number} favorite_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNativeV1MeFavoritesfavoriteId(
      favorite_id: number,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).deleteNativeV1MeFavoritesfavoriteId(favorite_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary account_suspension_token_validation <GET>
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1AccountSuspendTokenValidationtoken(
      token: string,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1AccountSuspendTokenValidationtoken(token, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_account_suspension_date <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1AccountSuspensionDate(options?: any): Promise<UserSuspensionDateResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1AccountSuspensionDate(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_account_suspension_status <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1AccountSuspensionStatus(options?: any): Promise<UserSuspensionStatusResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1AccountSuspensionStatus(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_artist <GET>
     * @param {string} artist_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ArtistsartistId(artist_id: string, options?: any): Promise<ArtistResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1ArtistsartistId(artist_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_banner <GET>
     * @param {boolean} [isGeolocated]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Banner(isGeolocated?: boolean, options?: any): Promise<BannerResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(configuration).getNativeV1Banner(
        isGeolocated,
        options
      )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_bookings <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Bookings(options?: any): Promise<BookingsResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1Bookings(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_categories <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Categories(options?: any): Promise<CategoriesResponseModel> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1Categories(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_cultural_survey_questions <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1CulturalSurveyQuestions(
      options?: any
    ): Promise<CulturalSurveyQuestionsResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1CulturalSurveyQuestions(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary email_validation_remaining_resends <GET>
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1EmailValidationRemainingResendsemail(
      email: string,
      options?: any
    ): Promise<EmailValidationRemainingResendsResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1EmailValidationRemainingResendsemail(email, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_user_profile <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Me(options?: any): Promise<UserProfileResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1Me(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_favorites <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1MeFavorites(options?: any): Promise<PaginatedFavoritesResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1MeFavorites(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_favorites_count <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1MeFavoritesCount(options?: any): Promise<FavoritesCountResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1MeFavoritesCount(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary google_oauth_state <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OauthState(options?: any): Promise<OauthStateResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1OauthState(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary report_offer_reasons <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OfferReportReasons(options?: any): Promise<OfferReportReasons> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1OfferReportReasons(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_offerer_headline_offer <GET>
     * @param {number} offerer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OffereroffererIdHeadlineOffer(
      offerer_id: number,
      options?: any
    ): Promise<OffererHeadLineOfferResponseModel> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1OffereroffererIdHeadlineOffer(offerer_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_offer <GET>
     * @deprecated
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OfferofferId(offer_id: number, options?: any): Promise<OfferResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1OfferofferId(offer_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary offer_chronicles <GET>
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OfferofferIdChronicles(
      offer_id: number,
      options?: any
    ): Promise<OfferChronicles> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1OfferofferIdChronicles(offer_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary user_reported_offers <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1OffersReports(options?: any): Promise<UserReportedOffersResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1OffersReports(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary phone_validation_remaining_attempts <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1PhoneValidationRemainingAttempts(
      options?: any
    ): Promise<PhoneValidationRemainingAttemptsRequest> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(
          configuration
        ).getNativeV1PhoneValidationRemainingAttempts(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_email_update_status <GET>
     * @deprecated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ProfileEmailUpdateStatus(options?: any): Promise<EmailUpdateStatus> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1ProfileEmailUpdateStatus(
          options
        )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_email_update_token_expiration_date <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ProfileTokenExpiration(options?: any): Promise<UpdateEmailTokenExpiration> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1ProfileTokenExpiration(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_available_reactions <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1ReactionAvailable(options?: any): Promise<GetAvailableReactionsResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1ReactionAvailable(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary similar_offers <GET>
     * @param {number} offer_id
     * @param {number} [longitude]
     * @param {number} [latitude]
     * @param {Array<string>} [categories]
     * @param {Array<string>} [subcategories]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1RecommendationSimilarOffersofferId(
      offer_id: number,
      longitude?: number,
      latitude?: number,
      categories?: Array<string>,
      subcategories?: Array<string>,
      options?: any
    ): Promise<SimilarOffersResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1RecommendationSimilarOffersofferId(
        offer_id,
        longitude,
        latitude,
        categories,
        subcategories,
        options
      )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_settings <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1Settings(options?: any): Promise<SettingsResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1Settings(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_subcategories_v2 <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubcategoriesV2(options?: any): Promise<SubcategoriesResponseModelv2> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1SubcategoriesV2(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_activity_types <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionActivityTypes(options?: any): Promise<ActivityTypesResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1SubscriptionActivityTypes(
          options
        )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary next_subscription_step <GET>
     * @deprecated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionNextStep(options?: any): Promise<NextSubscriptionStepResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1SubscriptionNextStep(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_profile <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionProfile(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1SubscriptionProfile(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_subscription_stepper_deprecated <GET>
     * @deprecated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1SubscriptionStepper(options?: any): Promise<SubscriptionStepperResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV1SubscriptionStepper(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_venue <GET>
     * @param {number} venue_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV1VenuevenueId(venue_id: number, options?: any): Promise<VenueResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV1VenuevenueId(venue_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_offer_v2 <GET>
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV2OfferofferId(offer_id: number, options?: any): Promise<OfferResponseV2> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).getNativeV2OfferofferId(offer_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_email_update_status <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV2ProfileEmailUpdateStatus(options?: any): Promise<EmailUpdateStatusResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV2ProfileEmailUpdateStatus(
          options
        )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_subscription_stepper <GET>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNativeV2SubscriptionStepper(options?: any): Promise<SubscriptionStepperResponseV2> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).getNativeV2SubscriptionStepper(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary patch_user_profile <PATCH>
     * @param {UserProfilePatchRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchNativeV1Profile(
      body?: UserProfilePatchRequest,
      options?: any
    ): Promise<UserProfileResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).patchNativeV1Profile(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary create_account <POST>
     * @param {AccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Account(body?: AccountRequest, options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1Account(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary anonymize_account <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountAnonymize(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1AccountAnonymize(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary suspend_account <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountSuspend(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1AccountSuspend(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary suspend_account_for_hack_suspicion <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountSuspendForHackSuspicion(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1AccountSuspendForHackSuspicion(
          options
        )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary suspend_account_for_suspicious_login <POST>
     * @param {SuspendAccountForSuspiciousLoginRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountSuspendForSuspiciousLogin(
      body?: SuspendAccountForSuspiciousLoginRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1AccountSuspendForSuspiciousLogin(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary unsuspend_account <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AccountUnsuspend(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1AccountUnsuspend(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary mark_achievements_as_seen <POST>
     * @param {MarkAchievementsAsSeenRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1AchievementsMarkAsSeen(
      body?: MarkAchievementsAsSeenRequest,
      options?: any
    ): Promise<AchievementsResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1AchievementsMarkAsSeen(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary book_offer <POST>
     * @param {BookOfferRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Bookings(body?: BookOfferRequest, options?: any): Promise<BookOfferResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1Bookings(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary cancel_booking <POST>
     * @param {number} booking_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1BookingsbookingIdCancel(
      booking_id: number,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1BookingsbookingIdCancel(booking_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary flag_booking_as_used <POST>
     * @param {number} booking_id
     * @param {BookingDisplayStatusRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1BookingsbookingIdToggleDisplay(
      booking_id: number,
      body?: BookingDisplayStatusRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1BookingsbookingIdToggleDisplay(booking_id, body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary change_password <POST>
     * @param {ChangePasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ChangePassword(
      body?: ChangePasswordRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ChangePassword(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary cookies_consent <POST>
     * @param {CookieConsentRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1CookiesConsent(
      body?: CookieConsentRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1CookiesConsent(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary post_cultural_survey_answers <POST>
     * @param {CulturalSurveyAnswersRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1CulturalSurveyAnswers(
      body?: CulturalSurveyAnswersRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1CulturalSurveyAnswers(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary post_feedback <POST>
     * @param {PostFeedbackBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Feedback(body?: PostFeedbackBody, options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1Feedback(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary create_favorite <POST>
     * @param {FavoriteRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1MeFavorites(
      body?: FavoriteRequest,
      options?: any
    ): Promise<FavoriteResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1MeFavorites(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary create_account_with_google_sso <POST>
     * @param {GoogleAccountRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OauthGoogleAccount(
      body?: GoogleAccountRequest,
      options?: any
    ): Promise<SigninResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1OauthGoogleAccount(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary google_auth <POST>
     * @param {GoogleSigninRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OauthGoogleAuthorize(
      body?: GoogleSigninRequest,
      options?: any
    ): Promise<SigninResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1OauthGoogleAuthorize(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary report_offer <POST>
     * @param {number} offer_id
     * @param {OfferReportRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OfferofferIdReport(
      offer_id: number,
      body?: OfferReportRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1OfferofferIdReport(offer_id, body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_offers_showtimes <POST>
     * @deprecated
     * @param {OffersStocksRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1OffersStocks(
      body?: OffersStocksRequest,
      options?: any
    ): Promise<OffersStocksResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1OffersStocks(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary patch_user_profile <POST>
     * @param {UserProfilePatchRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Profile(
      body?: UserProfilePatchRequest,
      options?: any
    ): Promise<UserProfileResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1Profile(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary cancel_email_update <POST>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ProfileEmailUpdateCancel(
      body?: ChangeBeneficiaryEmailBody,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ProfileEmailUpdateCancel(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary confirm_email_update <POST>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ProfileEmailUpdateConfirm(
      body?: ChangeBeneficiaryEmailBody,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ProfileEmailUpdateConfirm(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary update_user_email <POST>
     * @deprecated
     * @param {UserProfileEmailUpdate} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ProfileUpdateEmail(
      body?: UserProfileEmailUpdate,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ProfileUpdateEmail(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary post_reaction <POST>
     * @param {PostReactionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Reaction(body?: PostReactionRequest, options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1Reaction(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary playlist <POST>
     * @param {PlaylistRequestBody} [body]
     * @param {string} [modelEndpoint]
     * @param {number} [longitude]
     * @param {number} [latitude]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1RecommendationPlaylist(
      body?: PlaylistRequestBody,
      modelEndpoint?: string,
      longitude?: number,
      latitude?: number,
      options?: any
    ): Promise<PlaylistResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1RecommendationPlaylist(body, modelEndpoint, longitude, latitude, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary refresh <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1RefreshAccessToken(options?: any): Promise<RefreshResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1RefreshAccessToken(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary request_password_reset <POST>
     * @param {RequestPasswordResetRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1RequestPasswordReset(
      body?: RequestPasswordResetRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1RequestPasswordReset(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary resend_email_validation <POST>
     * @param {ResendEmailValidationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ResendEmailValidation(
      body?: ResendEmailValidationRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ResendEmailValidation(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary reset_password <POST>
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ResetPassword(
      body?: ResetPasswordRequest,
      options?: any
    ): Promise<ResetPasswordResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ResetPassword(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary reset_recredit_amount_to_show <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ResetRecreditAmountToShow(options?: any): Promise<UserProfileResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1ResetRecreditAmountToShow(
          options
        )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary send_offer_link_by_push <POST>
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SendOfferLinkByPushofferId(
      offer_id: number,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1SendOfferLinkByPushofferId(offer_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary On iOS native app, users cannot book numeric offers with price > 0, so give them webapp link.
     * @param {number} offer_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SendOfferWebappLinkByEmailofferId(
      offer_id: number,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1SendOfferWebappLinkByEmailofferId(offer_id, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary send_phone_validation_code <POST>
     * @param {SendPhoneValidationRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SendPhoneValidationCode(
      body?: SendPhoneValidationRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1SendPhoneValidationCode(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary signin <POST>
     * @param {SigninRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1Signin(body?: SigninRequest, options?: any): Promise<SigninResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(configuration).postNativeV1Signin(
        body,
        options
      )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary create_honor_statement_fraud_check <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SubscriptionHonorStatement(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV1SubscriptionHonorStatement(
          options
        )
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary complete_profile <POST>
     * @param {ProfileUpdateRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1SubscriptionProfile(
      body?: ProfileUpdateRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1SubscriptionProfile(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary start_identification_session <POST>
     * @param {IdentificationSessionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1UbbleIdentification(
      body?: IdentificationSessionRequest,
      options?: any
    ): Promise<IdentificationSessionResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1UbbleIdentification(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary validate_email <POST>
     * @param {ValidateEmailRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ValidateEmail(
      body?: ValidateEmailRequest,
      options?: any
    ): Promise<ValidateEmailResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ValidateEmail(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary validate_phone_number <POST>
     * @param {ValidatePhoneNumberRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV1ValidatePhoneNumber(
      body?: ValidatePhoneNumberRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV1ValidatePhoneNumber(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary get_offers_and_stocks <POST>
     * @param {OffersStocksRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2OffersStocks(
      body?: OffersStocksRequest,
      options?: any
    ): Promise<OffersStocksResponseV2> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV2OffersStocks(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary confirm_email_update <POST>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileEmailUpdateConfirm(
      body?: ChangeBeneficiaryEmailBody,
      options?: any
    ): Promise<EmailChangeConfirmationResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV2ProfileEmailUpdateConfirm(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary select_new_email <POST>
     * @param {NewEmailSelectionRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileEmailUpdateNewEmail(
      body?: NewEmailSelectionRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV2ProfileEmailUpdateNewEmail(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary select_new_password <POST>
     * @param {ResetPasswordRequest} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileEmailUpdateNewPassword(
      body?: ResetPasswordRequest,
      options?: any
    ): Promise<EmptyResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).postNativeV2ProfileEmailUpdateNewPassword(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary update_user_email <POST>
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNativeV2ProfileUpdateEmail(options?: any): Promise<EmptyResponse> {
      const localVarFetchArgs =
        await DefaultApiFetchParamCreator(configuration).postNativeV2ProfileUpdateEmail(options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
    /**
     *
     * @summary validate_user_email <PUT>
     * @param {ChangeBeneficiaryEmailBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putNativeV1ProfileEmailUpdateValidate(
      body?: ChangeBeneficiaryEmailBody,
      options?: any
    ): Promise<ChangeBeneficiaryEmailResponse> {
      const localVarFetchArgs = await DefaultApiFetchParamCreator(
        configuration
      ).putNativeV1ProfileEmailUpdateValidate(body, options)
      const response = await safeFetch(
        configuration?.basePath + localVarFetchArgs.url,
        localVarFetchArgs.options,
        api
      )
      return handleGeneratedApiResponse(response)
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary delete_favorite <DELETE>
   * @param {number} favorite_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async deleteNativeV1MeFavoritesfavoriteId(favorite_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).deleteNativeV1MeFavoritesfavoriteId(
      favorite_id,
      options
    )
  }
  /**
   *
   * @summary account_suspension_token_validation <GET>
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1AccountSuspendTokenValidationtoken(token: string, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1AccountSuspendTokenValidationtoken(
      token,
      options
    )
  }
  /**
   *
   * @summary get_account_suspension_date <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1AccountSuspensionDate(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1AccountSuspensionDate(options)
  }
  /**
   *
   * @summary get_account_suspension_status <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1AccountSuspensionStatus(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1AccountSuspensionStatus(options)
  }
  /**
   *
   * @summary get_artist <GET>
   * @param {string} artist_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1ArtistsartistId(artist_id: string, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1ArtistsartistId(artist_id, options)
  }
  /**
   *
   * @summary get_banner <GET>
   * @param {boolean} [isGeolocated]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1Banner(isGeolocated?: boolean, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1Banner(isGeolocated, options)
  }
  /**
   *
   * @summary get_bookings <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1Bookings(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1Bookings(options)
  }
  /**
   *
   * @summary get_categories <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1Categories(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1Categories(options)
  }
  /**
   *
   * @summary get_cultural_survey_questions <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1CulturalSurveyQuestions(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1CulturalSurveyQuestions(options)
  }
  /**
   *
   * @summary email_validation_remaining_resends <GET>
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1EmailValidationRemainingResendsemail(email: string, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1EmailValidationRemainingResendsemail(
      email,
      options
    )
  }
  /**
   *
   * @summary get_user_profile <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1Me(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1Me(options)
  }
  /**
   *
   * @summary get_favorites <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1MeFavorites(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1MeFavorites(options)
  }
  /**
   *
   * @summary get_favorites_count <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1MeFavoritesCount(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1MeFavoritesCount(options)
  }
  /**
   *
   * @summary google_oauth_state <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1OauthState(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1OauthState(options)
  }
  /**
   *
   * @summary report_offer_reasons <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1OfferReportReasons(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1OfferReportReasons(options)
  }
  /**
   *
   * @summary get_offerer_headline_offer <GET>
   * @param {number} offerer_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1OffereroffererIdHeadlineOffer(offerer_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1OffereroffererIdHeadlineOffer(
      offerer_id,
      options
    )
  }
  /**
   *
   * @summary get_offer <GET>
   * @deprecated
   * @param {number} offer_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1OfferofferId(offer_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1OfferofferId(offer_id, options)
  }
  /**
   *
   * @summary offer_chronicles <GET>
   * @param {number} offer_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1OfferofferIdChronicles(offer_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1OfferofferIdChronicles(offer_id, options)
  }
  /**
   *
   * @summary user_reported_offers <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1OffersReports(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1OffersReports(options)
  }
  /**
   *
   * @summary phone_validation_remaining_attempts <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1PhoneValidationRemainingAttempts(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1PhoneValidationRemainingAttempts(options)
  }
  /**
   *
   * @summary get_email_update_status <GET>
   * @deprecated
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1ProfileEmailUpdateStatus(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1ProfileEmailUpdateStatus(options)
  }
  /**
   *
   * @summary get_email_update_token_expiration_date <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1ProfileTokenExpiration(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1ProfileTokenExpiration(options)
  }
  /**
   *
   * @summary get_available_reactions <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1ReactionAvailable(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1ReactionAvailable(options)
  }
  /**
   *
   * @summary similar_offers <GET>
   * @param {number} offer_id
   * @param {number} [longitude]
   * @param {number} [latitude]
   * @param {Array<string>} [categories]
   * @param {Array<string>} [subcategories]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1RecommendationSimilarOffersofferId(
    offer_id: number,
    longitude?: number,
    latitude?: number,
    categories?: Array<string>,
    subcategories?: Array<string>,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1RecommendationSimilarOffersofferId(
      offer_id,
      longitude,
      latitude,
      categories,
      subcategories,
      options
    )
  }
  /**
   *
   * @summary get_settings <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1Settings(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1Settings(options)
  }
  /**
   *
   * @summary get_subcategories_v2 <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1SubcategoriesV2(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1SubcategoriesV2(options)
  }
  /**
   *
   * @summary get_activity_types <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1SubscriptionActivityTypes(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1SubscriptionActivityTypes(options)
  }
  /**
   *
   * @summary next_subscription_step <GET>
   * @deprecated
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1SubscriptionNextStep(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1SubscriptionNextStep(options)
  }
  /**
   *
   * @summary get_profile <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1SubscriptionProfile(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1SubscriptionProfile(options)
  }
  /**
   *
   * @summary get_subscription_stepper_deprecated <GET>
   * @deprecated
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1SubscriptionStepper(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1SubscriptionStepper(options)
  }
  /**
   *
   * @summary get_venue <GET>
   * @param {number} venue_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV1VenuevenueId(venue_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV1VenuevenueId(venue_id, options)
  }
  /**
   *
   * @summary get_offer_v2 <GET>
   * @param {number} offer_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV2OfferofferId(offer_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV2OfferofferId(offer_id, options)
  }
  /**
   *
   * @summary get_email_update_status <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV2ProfileEmailUpdateStatus(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV2ProfileEmailUpdateStatus(options)
  }
  /**
   *
   * @summary get_subscription_stepper <GET>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async getNativeV2SubscriptionStepper(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).getNativeV2SubscriptionStepper(options)
  }
  /**
   *
   * @summary patch_user_profile <PATCH>
   * @param {UserProfilePatchRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async patchNativeV1Profile(body?: UserProfilePatchRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).patchNativeV1Profile(body, options)
  }
  /**
   *
   * @summary create_account <POST>
   * @param {AccountRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1Account(body?: AccountRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1Account(body, options)
  }
  /**
   *
   * @summary anonymize_account <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1AccountAnonymize(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1AccountAnonymize(options)
  }
  /**
   *
   * @summary suspend_account <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1AccountSuspend(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1AccountSuspend(options)
  }
  /**
   *
   * @summary suspend_account_for_hack_suspicion <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1AccountSuspendForHackSuspicion(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1AccountSuspendForHackSuspicion(options)
  }
  /**
   *
   * @summary suspend_account_for_suspicious_login <POST>
   * @param {SuspendAccountForSuspiciousLoginRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1AccountSuspendForSuspiciousLogin(
    body?: SuspendAccountForSuspiciousLoginRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1AccountSuspendForSuspiciousLogin(
      body,
      options
    )
  }
  /**
   *
   * @summary unsuspend_account <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1AccountUnsuspend(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1AccountUnsuspend(options)
  }
  /**
   *
   * @summary mark_achievements_as_seen <POST>
   * @param {MarkAchievementsAsSeenRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1AchievementsMarkAsSeen(
    body?: MarkAchievementsAsSeenRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1AchievementsMarkAsSeen(body, options)
  }
  /**
   *
   * @summary book_offer <POST>
   * @param {BookOfferRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1Bookings(body?: BookOfferRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1Bookings(body, options)
  }
  /**
   *
   * @summary cancel_booking <POST>
   * @param {number} booking_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1BookingsbookingIdCancel(booking_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1BookingsbookingIdCancel(
      booking_id,
      options
    )
  }
  /**
   *
   * @summary flag_booking_as_used <POST>
   * @param {number} booking_id
   * @param {BookingDisplayStatusRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1BookingsbookingIdToggleDisplay(
    booking_id: number,
    body?: BookingDisplayStatusRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1BookingsbookingIdToggleDisplay(
      booking_id,
      body,
      options
    )
  }
  /**
   *
   * @summary change_password <POST>
   * @param {ChangePasswordRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ChangePassword(body?: ChangePasswordRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ChangePassword(body, options)
  }
  /**
   *
   * @summary cookies_consent <POST>
   * @param {CookieConsentRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1CookiesConsent(body?: CookieConsentRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1CookiesConsent(body, options)
  }
  /**
   *
   * @summary post_cultural_survey_answers <POST>
   * @param {CulturalSurveyAnswersRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1CulturalSurveyAnswers(
    body?: CulturalSurveyAnswersRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1CulturalSurveyAnswers(body, options)
  }
  /**
   *
   * @summary post_feedback <POST>
   * @param {PostFeedbackBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1Feedback(body?: PostFeedbackBody, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1Feedback(body, options)
  }
  /**
   *
   * @summary create_favorite <POST>
   * @param {FavoriteRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1MeFavorites(body?: FavoriteRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1MeFavorites(body, options)
  }
  /**
   *
   * @summary create_account_with_google_sso <POST>
   * @param {GoogleAccountRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1OauthGoogleAccount(body?: GoogleAccountRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1OauthGoogleAccount(body, options)
  }
  /**
   *
   * @summary google_auth <POST>
   * @param {GoogleSigninRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1OauthGoogleAuthorize(body?: GoogleSigninRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1OauthGoogleAuthorize(body, options)
  }
  /**
   *
   * @summary report_offer <POST>
   * @param {number} offer_id
   * @param {OfferReportRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1OfferofferIdReport(
    offer_id: number,
    body?: OfferReportRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1OfferofferIdReport(offer_id, body, options)
  }
  /**
   *
   * @summary get_offers_showtimes <POST>
   * @deprecated
   * @param {OffersStocksRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1OffersStocks(body?: OffersStocksRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1OffersStocks(body, options)
  }
  /**
   *
   * @summary patch_user_profile <POST>
   * @param {UserProfilePatchRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1Profile(body?: UserProfilePatchRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1Profile(body, options)
  }
  /**
   *
   * @summary cancel_email_update <POST>
   * @param {ChangeBeneficiaryEmailBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ProfileEmailUpdateCancel(
    body?: ChangeBeneficiaryEmailBody,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ProfileEmailUpdateCancel(body, options)
  }
  /**
   *
   * @summary confirm_email_update <POST>
   * @param {ChangeBeneficiaryEmailBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ProfileEmailUpdateConfirm(
    body?: ChangeBeneficiaryEmailBody,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ProfileEmailUpdateConfirm(body, options)
  }
  /**
   *
   * @summary update_user_email <POST>
   * @deprecated
   * @param {UserProfileEmailUpdate} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ProfileUpdateEmail(body?: UserProfileEmailUpdate, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ProfileUpdateEmail(body, options)
  }
  /**
   *
   * @summary post_reaction <POST>
   * @param {PostReactionRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1Reaction(body?: PostReactionRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1Reaction(body, options)
  }
  /**
   *
   * @summary playlist <POST>
   * @param {PlaylistRequestBody} [body]
   * @param {string} [modelEndpoint]
   * @param {number} [longitude]
   * @param {number} [latitude]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1RecommendationPlaylist(
    body?: PlaylistRequestBody,
    modelEndpoint?: string,
    longitude?: number,
    latitude?: number,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1RecommendationPlaylist(
      body,
      modelEndpoint,
      longitude,
      latitude,
      options
    )
  }
  /**
   *
   * @summary refresh <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1RefreshAccessToken(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1RefreshAccessToken(options)
  }
  /**
   *
   * @summary request_password_reset <POST>
   * @param {RequestPasswordResetRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1RequestPasswordReset(body?: RequestPasswordResetRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1RequestPasswordReset(body, options)
  }
  /**
   *
   * @summary resend_email_validation <POST>
   * @param {ResendEmailValidationRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ResendEmailValidation(
    body?: ResendEmailValidationRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ResendEmailValidation(body, options)
  }
  /**
   *
   * @summary reset_password <POST>
   * @param {ResetPasswordRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ResetPassword(body?: ResetPasswordRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ResetPassword(body, options)
  }
  /**
   *
   * @summary reset_recredit_amount_to_show <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ResetRecreditAmountToShow(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ResetRecreditAmountToShow(options)
  }
  /**
   *
   * @summary send_offer_link_by_push <POST>
   * @param {number} offer_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1SendOfferLinkByPushofferId(offer_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1SendOfferLinkByPushofferId(
      offer_id,
      options
    )
  }
  /**
   *
   * @summary On iOS native app, users cannot book numeric offers with price > 0, so give them webapp link.
   * @param {number} offer_id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1SendOfferWebappLinkByEmailofferId(offer_id: number, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1SendOfferWebappLinkByEmailofferId(
      offer_id,
      options
    )
  }
  /**
   *
   * @summary send_phone_validation_code <POST>
   * @param {SendPhoneValidationRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1SendPhoneValidationCode(
    body?: SendPhoneValidationRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1SendPhoneValidationCode(body, options)
  }
  /**
   *
   * @summary signin <POST>
   * @param {SigninRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1Signin(body?: SigninRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1Signin(body, options)
  }
  /**
   *
   * @summary create_honor_statement_fraud_check <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1SubscriptionHonorStatement(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1SubscriptionHonorStatement(options)
  }
  /**
   *
   * @summary complete_profile <POST>
   * @param {ProfileUpdateRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1SubscriptionProfile(body?: ProfileUpdateRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1SubscriptionProfile(body, options)
  }
  /**
   *
   * @summary start_identification_session <POST>
   * @param {IdentificationSessionRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1UbbleIdentification(body?: IdentificationSessionRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1UbbleIdentification(body, options)
  }
  /**
   *
   * @summary validate_email <POST>
   * @param {ValidateEmailRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ValidateEmail(body?: ValidateEmailRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ValidateEmail(body, options)
  }
  /**
   *
   * @summary validate_phone_number <POST>
   * @param {ValidatePhoneNumberRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV1ValidatePhoneNumber(body?: ValidatePhoneNumberRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV1ValidatePhoneNumber(body, options)
  }
  /**
   *
   * @summary get_offers_and_stocks <POST>
   * @param {OffersStocksRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV2OffersStocks(body?: OffersStocksRequest, options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV2OffersStocks(body, options)
  }
  /**
   *
   * @summary confirm_email_update <POST>
   * @param {ChangeBeneficiaryEmailBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV2ProfileEmailUpdateConfirm(
    body?: ChangeBeneficiaryEmailBody,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV2ProfileEmailUpdateConfirm(body, options)
  }
  /**
   *
   * @summary select_new_email <POST>
   * @param {NewEmailSelectionRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV2ProfileEmailUpdateNewEmail(
    body?: NewEmailSelectionRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV2ProfileEmailUpdateNewEmail(body, options)
  }
  /**
   *
   * @summary select_new_password <POST>
   * @param {ResetPasswordRequest} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV2ProfileEmailUpdateNewPassword(
    body?: ResetPasswordRequest,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV2ProfileEmailUpdateNewPassword(
      body,
      options
    )
  }
  /**
   *
   * @summary update_user_email <POST>
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async postNativeV2ProfileUpdateEmail(options?: any) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).postNativeV2ProfileUpdateEmail(options)
  }
  /**
   *
   * @summary validate_user_email <PUT>
   * @param {ChangeBeneficiaryEmailBody} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public async putNativeV1ProfileEmailUpdateValidate(
    body?: ChangeBeneficiaryEmailBody,
    options?: any
  ) {
    const configuration = this.getConfiguration()
    return DefaultApiFp(this, configuration).putNativeV1ProfileEmailUpdateValidate(body, options)
  }
}
