import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'
import { AccessibleIcon } from 'ui/svg/icons/types'

const EmailSentSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => {
  const height = typeof size === 'string' ? size : ((size as number) * 156) / 200
  return (
    <AccessibleSvg
      width={size}
      height={height}
      viewBox="0 0 200 156"
      accessibilityLabel={accessibilityLabel}
      testID={testID}>
      <Path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.6134 53.556C75.1981 49.3275 78.1026 45.8267 81.7873 45.4573L131.891 40.4337C133.162 40.3063 134.091 39.1827 133.968 37.924C133.844 36.6654 132.714 35.7483 131.444 35.8757L81.3397 40.8993C74.8564 41.5493 70.3758 47.5344 71.0125 54.0173L71.1006 54.9141L23.9087 59.6458C22.7471 59.7622 21.8971 60.7895 22.0101 61.9403C22.1231 63.0911 23.1564 63.9295 24.318 63.8131L71.5098 59.0814L76.7199 112.131C77.3566 118.614 82.914 123.593 89.3972 122.943L167.613 115.101C174.096 114.451 178.576 108.466 177.94 101.983L172.405 45.6228C172.402 45.5918 172.399 45.5609 172.396 45.5301L172.232 43.8685C171.596 37.3857 166.038 32.4071 159.555 33.0571L149.985 34.0166C148.715 34.144 147.785 35.2676 147.909 36.5263C148.032 37.7849 149.162 38.702 150.433 38.5746L160.003 37.6151C163.687 37.2457 167.216 40.1014 167.631 44.3299L167.693 44.9518L130.627 81.2326L130.622 81.2376C127.525 84.2883 122.688 84.7733 119.057 82.3972L84.8794 60.1591C83.8142 59.4661 82.3804 59.7622 81.677 60.8205C80.9735 61.8789 81.2667 63.2987 82.3319 63.9917L106.909 79.9826L81.4653 112.597C81.4005 112.296 81.3519 111.987 81.3208 111.67L75.6134 53.556ZM83.9977 116.826L110.777 82.4997L116.5 86.223L116.502 86.2247C121.957 89.7932 129.218 89.0653 133.872 84.4831L133.874 84.4809L138.774 79.6848L151.537 90.6746C152.498 91.5027 153.958 91.3988 154.798 90.4425C155.637 89.4861 155.538 88.0395 154.576 87.2113L142.079 76.45L168.268 50.8148L173.339 102.444C173.37 102.761 173.382 103.073 173.377 103.38L161.274 92.9609C160.312 92.1328 158.852 92.2369 158.013 93.1934C157.173 94.1499 157.273 95.5965 158.235 96.4245L171.717 108.031C170.585 109.431 168.969 110.362 167.165 110.543L88.9496 118.385C87.146 118.566 85.3797 117.974 83.9977 116.826Z"
      />
      <Path
        fill={color}
        d="M35.2009 82.7114C34.0393 82.8279 33.1893 83.8552 33.3023 85.006C33.4153 86.1567 34.4486 86.9952 35.6102 86.8787L62.9527 84.1373C64.1143 84.0208 64.9644 82.9935 64.8514 81.8427C64.7383 80.692 63.7051 79.8535 62.5435 79.97L35.2009 82.7114Z"
      />
      <Path
        fill={color}
        d="M44.9669 106.456C43.8053 106.573 42.9552 107.6 43.0682 108.751C43.1812 109.901 44.2145 110.74 45.3761 110.623L71.667 107.987C72.8286 107.871 73.6787 106.844 73.5657 105.693C73.4526 104.542 72.4194 103.704 71.2577 103.82L44.9669 106.456Z"
      />
    </AccessibleSvg>
  )
}

export const EmailSent = styled(EmailSentSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.illustrations.sizes.medium,
}))``
