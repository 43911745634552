export enum QueryKeys {
  ACCOUNT_SUSPEND_TOKEN_VALIDATION = 'accountSuspendTokenValidation',
  ACCOUNT_SUSPENSION_DATE = 'accountSuspensionDate',
  ACCOUNT_SUSPENSION_STATUS = 'accountSuspensionStatus',
  ACTIVITY_TYPES = 'activityTypes',
  ADDRESSES = 'addresses',
  ALGOLIA_SIMILAR_OFFERS = 'algoliaSimilarOffers',
  ARTIST_PLAYLIST = 'artistPlaylist',
  AVAILABLE_REACTION = 'availableReaction',
  BOOKINGS = 'bookings',
  CINEMA_OFFERS = 'cinemaOffers',
  CITIES = 'cities',
  COOKIES_DATA = 'cookiesData',
  CONCERTS_AND_FESTIVALS = 'concertsAndFestivals',
  CULTURAL_SURVEY_ANSWERS = 'culturalSurveyAnswers',
  CULTURAL_SURVEY_QUESTIONS = 'culturalSurveyQuestions',
  EMAIL_CHANGE_EXPIRATION_TIMESTAMP = 'emailChangeExpirationTimestamp',
  EMAIL_UPDATE_STATUS = 'emailUpdateStatus',
  EMAIL_UPDATE_STATUS_V2 = 'emailUpdateStatusV2',
  EMAIL_VALIDATION_REMAINING_ATTEMPTS = 'emailValidationRemainingAttempts',
  ERROR_ASYNC = 'errorAsync',
  FAVORITES = 'favorites',
  FAVORITES_COUNT = 'favoritesCount',
  FEATURE_FLAGS = 'featureFlags',
  FILMS_OFFERS = 'filmsOffers',
  FIRESTORE_UBBLE_ETA_MESSAGE = 'firestoreUbbleETAMessage',
  HEADLINE_OFFERS_COUNT = 'headlineOffersCount',
  HIGHLIGHT_OFFER = 'highlightOffer',
  HOME_BANNER = 'homeBanner',
  HOME_MODULE = 'homeModule',
  HOME_VENUES_MODULE = 'homeVenuesModule',
  HOMEPAGE_MODULES = 'homepageModules',
  MAINTENANCE = 'maintenance',
  MINIMAL_BUILD_NUMBER = 'minimalBuildNumber',
  MUSIC_OFFERS = 'musicOffers',
  NEXT_SUBSCRIPTION_STEP = 'nextSubscriptionStep',
  OAUTH_STATE = 'oauthState',
  OFFER = 'offer',
  OFFER_CHRONICLES = 'offerChronicles',
  PHONE_VALIDATION_CODE = 'phoneValidationCode',
  PHONE_VALIDATION_REMAINING_ATTEMPTS = 'phoneValidationRemainingAttempts',
  PLACES = 'places',
  RECOMMENDATION_HITS = 'recommendationHits',
  RECOMMENDATION_OFFER_IDS = 'recommendationOfferIds',
  REMOTE_CONFIG = 'remoteConfig',
  RESET_PASSWORD_EXPIRED_LINK = 'resetPasswordExpiredLink',
  SEARCH_N1_BOOKS_GTL_PLAYLISTS = 'searchN1BooksGtlPlaylists',
  SEARCH_RESULTS = 'searchResults',
  SETTINGS = 'settings',
  SIGNUP_CONFIRMATION_EXPIRED_LINK = 'signupConfirmationExpiredLink',
  SIMILAR_OFFERS_IDS = 'similarOffersIds',
  STEPPER_INFO = 'stepperInfo',
  SUBCATEGORIES = 'subcategories',
  SUBSCRIPTION_PROFILE_INFO = 'subscriptionProfileInfo',
  USER_PROFILE = 'userProfile',
  VENUE = 'venue',
  VENUE_GTL_PLAYLISTS = 'venueGtlPlaylists',
  VENUE_OFFERS = 'venueOffers',
  VENUES = 'venues',
  VIDEO_CAROUSEL_OFFERS = 'videoCarouselOffers',
  VIDEO_OFFER = 'video_offer',
}

export enum MutationKeys {
  IDENTIFICATION_URL = 'identificationUrl',
}
