import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const ArrowAgainSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID = 'ArrowAgain',
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    testID={testID}
    accessibilityLabel={accessibilityLabel}>
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5351 3.86918C35.0143 3.59427 35.18 2.98271 34.9053 2.5032C34.6306 2.0237 34.0195 1.85783 33.5404 2.13274L26.9759 5.89912C26.7437 6.03235 26.5746 6.25319 26.5063 6.51221C26.4381 6.77124 26.4765 7.04683 26.6129 7.27732L30.5901 13.9981C30.8715 14.4736 31.4849 14.6309 31.9601 14.3492C32.4353 14.0675 32.5923 13.4537 32.3109 12.9781L29.5518 8.31568C32.9483 9.47639 35.8965 11.6764 37.9591 14.6116C40.2518 17.8742 41.3022 21.8343 40.9243 25.7898C40.5463 29.7453 38.7643 33.4407 35.8942 36.2206C33.0242 39.0006 29.2516 40.6855 25.2453 40.9766C21.239 41.2677 17.2579 40.1462 14.0079 37.811C10.7579 35.4757 8.44899 32.0777 7.49059 28.2192C6.53219 24.3608 6.98621 20.2914 8.77215 16.7325C10.4084 13.4718 13.0658 10.8328 16.335 9.19963C16.8304 8.95216 17.0671 8.36546 16.8463 7.86089C16.6284 7.36289 16.0465 7.13256 15.5558 7.37329C11.8459 9.19352 8.82985 12.1681 6.98068 15.853C4.98463 19.8306 4.47719 24.3788 5.54834 28.6912C6.6195 33.0035 9.20002 36.8014 12.8324 39.4114C16.4647 42.0213 20.9142 43.2748 25.3918 42.9494C29.8694 42.624 34.0859 40.7409 37.2936 37.6339C40.5013 34.5269 42.4929 30.3968 42.9154 25.9759C43.3378 21.5551 42.1638 17.129 39.6014 13.4827C37.2756 10.1729 33.9424 7.69966 30.1037 6.41173L34.5351 3.86918Z"
    />
  </AccessibleSvg>
)

export const ArrowAgain = styled(ArrowAgainSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.standard,
}))``
