import React from 'react'
import { Path } from 'react-native-svg'
import styled from 'styled-components/native'

import { AccessibleSvg } from 'ui/svg/AccessibleSvg'

import { AccessibleIcon } from './types'

const StockSvg: React.FunctionComponent<AccessibleIcon> = ({
  size,
  color,
  accessibilityLabel,
  testID,
}) => (
  <AccessibleSvg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    accessibilityLabel={accessibilityLabel}
    testID={testID}>
    <Path
      fill={color}
      clipRule="evenodd"
      fillRule="evenodd"
      d="M20.218 14.6016H24.3003C24.3003 14.6016 25.1055 17.6016 28.6055 17.5489C32.1055 17.4963 32.9106 14.6016 32.9106 14.6016H36.993C38.4358 14.6016 39.6055 15.7768 39.6055 17.2266L39.6055 39.9766C39.6055 41.4263 38.4358 42.6016 36.993 42.6016H32.9106C32.4964 42.6016 32.1498 42.3028 31.9901 41.9188C31.4369 40.5888 30.1298 39.6542 28.6055 39.6542C27.0811 39.6542 25.7741 40.5888 25.2209 41.9188C25.0612 42.3028 24.7145 42.6016 24.3003 42.6016H20.218C18.7751 42.6016 17.6055 41.4263 17.6055 39.9766V17.2266C17.6055 15.7768 18.7751 14.6016 20.218 14.6016Z"
    />
    <Path
      fill={color}
      clipRule="evenodd"
      fillRule="evenodd"
      d="M15.5377 6.92672L11.5708 7.50066C9.93103 7.73791 8.79405 9.25954 9.0313 10.8993L12.3883 34.1014C12.5666 35.334 13.4706 36.2825 14.6051 36.5768V17.2265C14.6051 14.1335 17.1044 11.6015 20.2176 11.6015H24.3733C25.6874 11.6015 26.8377 12.4839 27.1783 13.753C27.1834 13.7655 27.191 13.7837 27.2015 13.8064C27.2445 13.8988 27.3154 14.0235 27.4154 14.1398C27.5524 14.2993 27.8139 14.5605 28.5599 14.5492C29.3628 14.5372 29.6649 14.2486 29.8121 14.0803C29.9142 13.9635 29.9835 13.8436 30.0232 13.7604C30.0311 13.744 30.0371 13.7305 30.0414 13.7204C30.3709 12.5357 31.4094 11.6962 32.621 11.609L32.0368 7.57081C31.7995 5.93103 30.2779 4.79405 28.6381 5.0313L24.6707 5.60532C24.2 5.67342 23.8559 6.06904 23.7294 6.52752C23.3328 7.96576 22.1191 9.10606 20.548 9.33338C18.9769 9.56069 17.4898 8.81114 16.7017 7.54431C16.4505 7.14048 16.0084 6.85862 15.5377 6.92672ZM33.1543 15.2951C32.6202 15.6386 32.1206 16.0657 31.6533 16.4708C30.9943 17.0419 30.0199 17.5276 28.6051 17.5489C27.181 17.5703 26.2031 17.0864 25.544 16.5048C24.5262 15.6067 23.3563 14.6015 21.9989 14.6015H20.2176C18.7747 14.6015 17.6051 15.7768 17.6051 17.2265V36.3778L19.7532 36.067C20.2239 35.9989 20.5681 35.6033 20.6945 35.1448C21.0912 33.7065 22.3049 32.5662 23.876 32.3388C25.4471 32.1115 26.9342 32.8611 27.7223 34.128C27.9735 34.5318 28.4157 34.8137 28.8864 34.7456L32.8542 34.1715C34.494 33.9343 35.631 32.4127 35.3937 30.7729L33.1543 15.2951Z"
    />
  </AccessibleSvg>
)

export const Stock = styled(StockSvg).attrs(({ color, size, theme }) => ({
  color: color ?? theme.colors.black,
  size: size ?? theme.icons.sizes.smaller,
}))``
